<template>
    <v-card>
        <v-toolbar color="primary" dark>
            <span class="text-h5 ml-3">検索条件</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeModal">
                <v-icon large>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-form ref="productSearchform" @submit.prevent="searchProductList(1)">
              <v-row align="center" class="mt-4">
                <v-col cols="4">
                    <p class="mb-0 text-subtitle-1 ml-16">商品コード</p>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="productCode"
                        autofocus
                        outlined
                        clearable
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
              <v-row align="center">
                <v-col cols="4">
                    <p class="mb-0 text-subtitle-1 ml-16">品番</p>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="productNumber"
                        outlined
                        clearable
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
              <v-row align="center">
                <v-col cols="4">
                    <p class="mb-0 text-subtitle-1 ml-16">商品名</p>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="productName"
                        outlined
                        clearable
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
              <v-row align="center">
                <v-col cols="4">
                    <p class="mb-0 text-subtitle-1 ml-16">商品名(カナ)</p>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="productNameKana"
                        outlined
                        clearable
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
              <v-row align="center">
                <v-col cols="4">
                    <p class="mb-0 text-subtitle-1 ml-16">単品バーコード</p>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="barcode"
                        outlined
                        clearable
                        hide-details
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
              <!-- 検索ボタンと入力クリアボタン -->
              <v-card-actions class="justify-center py-3">
                <v-btn
                  rounded
                  color="success"
                  x-large
                  type="submit"
                  class="px-8 mr-10"
                >入力した内容で検索</v-btn>
                <v-btn rounded color="warning" x-large class="px-12" @click="productSearchReset">入力クリア</v-btn>
              </v-card-actions>
            </v-form>
        </v-card-text>
        <v-divider class="mx-4 mt-2"></v-divider>
        <!-- 検索結果モーダル -->
        <v-dialog v-model="searchResulDialog" persistent max-width="95%" scrollable>
            <v-card>
                <v-toolbar color="primary" dark>
                    <span class="text-h5 ml-3">検索結果</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="clearData">
                        <v-icon large>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-title class="pl-4 pt-3 pb-0 text-h6 font-weight-bold blue--text text--darken-2">
                    ※ダブルクリックで商品決定
                </v-card-title>
                <!-- 検索結果の商品一覧テーブル -->
                <v-data-table height="650px"
                    :items-per-page="-1"
                    :headers="searchResultHeaders"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    fixed-header
                    :items="scrollDataList"
                    :loading="loadingCount > 0"
                    loading-text="読み込み中"
                    hide-default-footer
                    no-data-text="検索条件に一致する商品はありません"
                    class="elevation-3 mx-4 mb-2 mt-0"
                    @dblclick:row="tableAddition"
                    >
                  <template v-slot:[`item.productName`]="{ item }">
                    <tooltip-item-table :tooltip-text="item.productName"/>
                  </template>
                  <template v-slot:[`item.productNameKana`]="{ item }">
                    <tooltip-item-table :tooltip-text="item.productNameKana"/>
                  </template>
                  <template v-slot:foot>
                    <v-row v-intersect="infiniteScrolling"></v-row>
                  </template>
                </v-data-table>

                <v-card-text style="height: 600px;"></v-card-text>

            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import getProductService from '@/services/getProductService.js';
import TooltipItemTable from '@/components/TooltipItemTable.vue';

export default {
    components: {
      TooltipItemTable,
    },
    data() {
        return {
            // 検索結果テーブルのページネーション関係
            sortBy: 'id',
            sortDesc: [true],
            page: 1,
            pageCount: null,
            // 1ページに表示する行数の指定
            itemsPerPage: 30,

            // 商品コード
            productCode: '',
            // 品番
            productNumber: '',
            // 商品名
            productName: '',
            // 商品名(カナ)
            productNameKana: '',
            // 単品バーコード
            barcode: '',

            // 検索結果テーブルのヘッダー
            searchResultHeaders: [
                { text: '商品コード', value: 'productCode' },
                { text: '品番', value: 'productNumber' },
                { text: '商品名', value: 'productName' },
                { text: '商品名(カナ)', value: 'productNameKana' },
                { text: '単品バーコード', value: 'barcode' },
                // { text: '追加', value: 'addition', sortable: false },
            ],
            // 検索結果モーダルflg
            searchResulDialog: false,
            // loading時間
            loadingCount: 0,
          // scroll loading
          scrollDataList: [],
        }
    },
    methods: {
        // テーブルに商品の追加
        tableAddition(event, { item }) {
            // 親コンポーネント(ProductInfomation.vue)に追加ボタンの押された商品/サービスのデータを渡す
            this.$emit("add-table", item);
            this.closeModal();
            this.clearData();
            this.scrollDataList = [];
        },

        // 入力クリアボタンイベント
        productSearchReset() {
            this.$refs.productSearchform.reset();
        },
        closeModal(){
          this.productSearchReset();
          this.$emit('modal-close');
        },
        clearData () {
          this.loadingCount = 0;
          this.scrollDataList = [];
          this.page = 1;
          this.pageCount = 0;
          this.searchResulDialog = false
        },

        // 商品/サービス情報の検索結果取得
        searchProductList: async function ( page = null) {
            // 検索結果モーダルを開く
            if (page) this.page = page
            this.searchResulDialog = true;

            this.loadingCount++;
            try {
                const response = await getProductService.getProductSearchList(
                    this.productCode,
                    this.productNumber,
                    this.productName,
                    this.productNameKana,
                    this.barcode,
                    {
                      limit:  this.itemsPerPage,
                      page: this.page,
                      orderBy: !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy,
                      orderDir: !this.sortDesc[0] ? 'asc' : 'desc'
                    }
                );
                // 取得したデータを格納
                let searchResulProductList = response.data.data.data;
                this.pageCount = response.data.data.last_page
                if (searchResulProductList.length > 0) {
                  searchResulProductList.forEach(item => {
                    this.scrollDataList.push(item);
                  });
                }
            } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
        },

      //scroll to load
      async infiniteScrolling() {
        if(this.page < this.pageCount){
          this.page++
          await this.searchProductList()
        }
      },
    },
    watch: {
      async sortBy() {
        this.clearData()
        await this.searchProductList()
      },
      async sortDesc() {
        this.clearData()
        await this.searchProductList()
      },
    }
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
    font-size: 18px !important;
    background-color: #bdbdbd;
    white-space: nowrap;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    vertical-align: top;
}
/* 検索結果が「０」の場合に表示するテキストのフォントサイズ */
::v-deep .v-data-table__empty-wrapper > td {
    font-size: 20px !important;
}
</style>
