<template>
  <v-card color="grey lighten-5" class="my-3 mx-5">
    <template>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              文書名
            </th>
            <th class="text-left">
              作成日
            </th>
            <th class="text-left">
              ダウンロード期間
            </th>
            <th class="text-left">
              ダウンロード
            </th>
            <th class="text-center">
              削除
            </th>
          </tr>
          </thead>
          <template>
            <v-row justify="center">
              <v-dialog
                v-model="act_dialog"
                persistent
                max-width="500"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    選択文書ダウンロード予約
                  </v-card-title>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue-grey darken-1"
                      rounded
                      dark
                      @click="act_dialog = false"
                    >
                      <v-icon dark>
                        mdi-close-octagon
                      </v-icon>
                      キャンセル
                    </v-btn>
<!--                    action download -->
                    <v-btn v-if="action == 0"
                      color="success"
                      rounded
                      @click="confirmDownloadFile"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                      ダウンロード
                    </v-btn>
<!--                    action delete-->
                    <v-btn v-else-if="action == 1"
                      color="error"
                      rounded
                      @click="deleteDownloadFile"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                      削除
                    </v-btn>
<!--                    action retry download -->
                    <v-btn v-else
                      color="success"
                      rounded
                      @click="retryDownLoadFile"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>

          <tbody>
          <tr v-for="file in downLoadFileList" :key="file.id">
            <td class="font-weight-bold">{{ file.file_name }}</td>
            <td>{{file.created_at}}</td>
            <td>{{file.download_period}}</td>
            <td v-if="file.status == 0">処理待ち</td>
            <td v-else-if="file.status == 1">
              <v-btn color="warning" depressed @click="confirmFileActionDialog(file.id,0)"><v-icon>mdi-cloud-download</v-icon> ダウンロード</v-btn>
            </td>
            <td class="red--text" v-else><v-btn dark color="grey darken-2" @click="confirmFileActionDialog(file.id,2)">失敗</v-btn></td>
            <td>
              <div class="d-flex justify-center">
                <v-btn color="error" depressed @click="confirmFileActionDialog(file.id,1)">削除</v-btn>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="downLoadFileList.length < 1" class="text-center">データがありません</div>
    </template>

    <v-overlay :value="loadingCount > 0">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>

import downloadService from "@/services/downloadService";
import download from "downloadjs";

const pako = require('pako');
// const ACT_DOWNLOAD = 0;
// const ACT_DELETE = 1;
// const ACT_RETRY = 2;

export default {
  props:{
    downLoadFileList: Array,
  },

  data() {
    return {
      loadingCount: 0,

      act_dialog: false,
      fileId: null,
      action: null,
    }
  },
  methods:{
    confirmFileActionDialog(fileId,act){
      this.fileId = fileId;
      this.act_dialog = true;
      this.action = act;
    },

    async confirmDownloadFile(){
      this.act_dialog = false;
      this.loadingCount++;
      await downloadService.downloadFile(this.fileId)
        .then((res) => {
          const fileInfo = res.data.data;
          const fileName = fileInfo.file_name;
          const fileData = fileInfo.fileData;

          // Decode base64 (convert ascii to binary)
          let strData     = atob(fileData);

          // Convert binary string to character-number array
          let charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

          // Turn number array into byte-array
          let binData     = new Uint8Array(charData);
          const output = pako.ungzip(binData)

          download(output,fileName,'text/csv');
        })
        .catch((err) => {
          if (err.response.status === 403){
            this.$store.dispatch('snackbar/activateErrorNotification',{message: err.response.data.message});
          }
        });
      this.loadingCount--;
    },

    async deleteDownloadFile(){
      this.act_dialog = false;
      this.loadingCount++;
      await downloadService.deleteFileDownLoad(this.fileId)
        .then(() => this.$emit('deleteFile'));
      this.loadingCount--;
    },

    async retryDownLoadFile(){
      this.act_dialog = false;
      this.loadingCount++;
      await downloadService.retryDownLoadMattersFile(this.fileId)
        .then(() => this.$emit('retryDownload'));
      this.loadingCount--;
    }

  },
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #90caf9;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
  vertical-align: top;
}
.v-data-table ::v-deep th:nth-child(1) {
  width: 20% !important;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
  /* font-size: 10px !important; */
  background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
  background: #fff;
}

.v-data-table ::v-deep tr:hover td {
  background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
  /* font-family: sans-serif !important; */
}
.v-dialog__content{
  top: -200px;
}
</style>
