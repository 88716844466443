<template>
    <v-card elevation-24 :style="this.bgcolor" class="ml-1 mr-1" id="dashOneData">
      <v-card-text class="py-2 px-2">
        <b style="font-size: 14px">{{ pTitle }}</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-btn :disabled="checkBtnDisable == null || $store.state.auth.user.isViewer || $store.state.auth.user.isGuest" block class="text-center" style="background-color: inherit;height: 50px;font-size: 17px;padding: 0px" @click="gotoMatterListSearch">{{ pCount }}件</v-btn>
    </v-card>
</template>

<script>
import constant from '@/components/helpers/const';
export default {
  name: 'dash_onedata',
  data: function () {
    return {
      bgcolor: 'width: 88px; background-color: #' + this.pBgcolor + ';',
      eventCheck: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
      checkBtnDisable: null,
    }
  },
  props: {
    pTitle: String,
    pCount: Number,
    pBgcolor: String,
    pEventKey: Number,
    serverFlag: Number,
  },
  methods: {
    gotoMatterListSearch() {
      this.eventCheck.forEach(item=>{
        if (item == this.pEventKey){
          let cookieParams = ['matter_list_start_date','matter_list_end_date','matter_list_user_ids','matter_list_last_name',
            'matter_list_last_name_kana','matter_list_company_name','matter_list_company_name_kana','matter_list_email',
            'matter_list_phone_or_fax','matter_list_address','matter_list_matter_number','matter_list_inquiry_type',
            'matter_list_category','matter_list_no_date_specified','matter_list_selected_status_flgs','matter_list_defect_flg', 'matter_list_request_lg',
            'matter_list_product_code','matter_list_product_number','matter_list_product_name','matter_list_checked_flg','matter_list_page','matter_list_per_page',
            'oshop_matter_list_start_date','oshop_matter_list_end_date','oshop_matter_list_user_ids','oshop_matter_list_last_name',
            'oshop_matter_list_last_name_kana','oshop_matter_list_company_name','oshop_matter_list_company_name_kana','oshop_matter_list_email',
            'oshop_matter_list_phone_or_fax','oshop_matter_list_address','oshop_matter_list_matter_number','oshop_matter_list_inquiry_type',
            'oshop_matter_list_category','oshop_matter_list_no_date_specified','oshop_matter_list_selected_status_flgs','oshop_matter_list_defect_flg', 'oshop_matter_list_request_lg',
            'oshop_matter_list_product_code','oshop_matter_list_product_number','oshop_matter_list_product_name','oshop_matter_list_checked_flg','oshop_matter_list_page','oshop_matter_list_per_page',
            'sid_matter_list_start_date','sid_matter_list_end_date','sid_matter_list_user_ids','sid_matter_list_last_name',
            'sid_matter_list_last_name_kana','sid_matter_list_company_name','sid_matter_list_company_name_kana','sid_matter_list_email',
            'sid_matter_list_phone_or_fax','sid_matter_list_address','sid_matter_list_matter_number','sid_matter_list_inquiry_type',
            'sid_matter_list_category','sid_matter_list_no_date_specified','sid_matter_list_selected_status_flgs','sid_matter_list_defect_flg', 'sid_matter_list_request_lg',
            'sid_matter_list_product_code','sid_matter_list_product_number','sid_matter_list_product_name','sid_matter_list_checked_flg','sid_matter_list_page','sid_matter_list_per_page',
            'mo_matter_list_start_date','mo_matter_list_end_date','mo_matter_list_user_ids','mo_matter_list_last_name',
            'mo_matter_list_last_name_kana','mo_matter_list_company_name','mo_matter_list_company_name_kana','mo_matter_list_email',
            'mo_matter_list_phone_or_fax','mo_matter_list_address','mo_matter_list_matter_number','mo_matter_list_inquiry_type',
            'mo_matter_list_category','mo_matter_list_no_date_specified','mo_matter_list_selected_status_flgs','mo_matter_list_defect_flg', 'mo_matter_list_request_lg',
            'mo_matter_list_product_code','mo_matter_list_product_number','mo_matter_list_product_name','mo_matter_list_checked_flg','mo_matter_list_page','mo_matter_list_per_page'];
          cookieParams.forEach(item =>
            this.$cookies.remove(item),
          )
          this.$store.commit( 'setDash2mattelst',  this.pEventKey )
          if (this.$store.state.auth.user.isViewer || this.$store.state.auth.user.isGuest){
            // 問い合せ一覧へ画面遷移
            this.$router.push('/all-matter-search')
          }else {
            // 問い合せ一覧へ画面遷移
            switch (this.serverFlag) {
              case constant.serverFlag.webcs:
                this.$router.push('/webcs-matter-list');
                break;
              case constant.serverFlag.oshop:
                this.$router.push('/oshop-matter-list');
                break;
              case constant.serverFlag.sid:
                this.$router.push('/sid-matter-list');
                break;
              case constant.serverFlag.mo:
                this.$router.push('/mo-matter-list');
                break;
              default:
                this.$router.push('/webcs-matter-list');
                break;
            }
          }
        }
      })
    }
  },
 created() {
   this.eventCheck.forEach(item=>{
     if (item == this.pEventKey){
       this.checkBtnDisable = this.pEventKey;
     }
   })
  }
}
</script>
<style scoped>
#dashOneData .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
  background-color: inherit!important;
}
#dashOneData .theme--light.v-btn.v-btn--disabled {
  color: inherit!important;
}
</style>
