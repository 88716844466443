import http from '@/httpCommon.js';

export default {
  // お客様情報の取得
  async getCustomerList(customerLastName, customerLastNameKana, companyName, companyNameKana, email1, gender, phoneNumber1, phoneNumber2, ndDisplayTelSearch, postalCode,prefectureName,address, queryData) {
    let query = ''
    const key = Object.keys(queryData)
    const value = Object.values(queryData)

    for (let i = 0; i < key.length; i++) {
      if (i == 0) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }
    return http.get(`/customer-search${query}`, {
      params: {
        lastName: customerLastName,
        lastNameKana: customerLastNameKana,
        companyName: companyName,
        companyNameKana: companyNameKana,
        email1: email1,
        gender: gender,
        phoneNumber1: phoneNumber1,
        phoneNumber2: phoneNumber2,
        postalCode: postalCode,
        prefectureName: prefectureName,
        address: address,
        ndDisplayTelSearch: ndDisplayTelSearch
      },
    });
  },

};
