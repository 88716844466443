import http from '@/httpCommon.js';

// function sleep(ms) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, ms);
//   });
// }

export default {
  // 問い合せ一覧の取得
  async getMatterList(searchCondition, page, per_page, sortBy, sortDesc) {
    // await sleep(500);
    return http.get('/get-matter-list', {
      params: {
        status: searchCondition.selectedStatusFlgs,
        start_date: searchCondition.periodStartDate,
        end_date: searchCondition.periodEndDate,
        user_id: searchCondition.selectedUserList,
        last_name: searchCondition.customerLastName,
        last_name_kana: searchCondition.customerLastNameKana,
        company_name: searchCondition.companyName,
        company_name_kana: searchCondition.companyNameKana,
        email: searchCondition.email,
        phone_number_or_fax: searchCondition.phoneNumberOrFax,
        address: searchCondition.address,
        product_code: searchCondition.productCode,
        product_number: searchCondition.productNumber,
        product_name: searchCondition.productName,
        matter_number: searchCondition.matterNumber,
        inquiry_type: searchCondition.inquiryType,
        category: searchCondition.category,
        page: page,
        limit: per_page,
        orderBy: sortBy,
        orderDir: sortDesc,

        product_id: searchCondition.product_id,
        subtype_id: searchCondition.subtype_id,
        aggregate_type_id: searchCondition.aggregateType_id,
        checked_flg: searchCondition.checkedFlg,
        categoryFlg: searchCondition.categoryFlg,
        countEachCategory: searchCondition.countEachCategory,
        setClassification: true,
        server_flg: searchCondition.serverFlag,
        defectFlg: searchCondition.defectFlg || null,
        requestFlg: searchCondition.requestFlg || null,
      },
    });
  },

  async getMattersForCSV(searchCondition,fileName,fileHeaders,fileFields,sortBy, sortDesc) {
    return http.get('/get-matter-list-export', {
      params: {
        orderBy: sortBy,
        orderDir: sortDesc,

        status: searchCondition.selectedStatusFlgs,
        start_date: searchCondition.periodStartDate,
        end_date: searchCondition.periodEndDate,
        user_id: searchCondition.selectedUserList,
        last_name: searchCondition.customerLastName,
        last_name_kana: searchCondition.customerLastNameKana,
        company_name: searchCondition.companyName,
        company_name_kana: searchCondition.companyNameKana,
        email: searchCondition.email,
        phone_number_or_fax: searchCondition.phoneNumberOrFax,
        address: searchCondition.address,
        matter_number: searchCondition.matterNumber,
        inquiry_type: searchCondition.inquiryType,
        product_id: searchCondition.product_id,
        subtype_id: searchCondition.subtype_id,
        aggregate_type_id: searchCondition.aggregateType_id,
        category: searchCondition.category,
        categoryFlg: searchCondition.categoryFlg,
        fileName: fileName,
        fileHeaders: fileHeaders,
        fileFields: fileFields,
        checked_flg: searchCondition.checkedFlg,
        defectFlg: searchCondition.defectFlg || null,
        requestFlg: searchCondition.requestFlg || null,
        server_flg: searchCondition.serverFlag,
      },
    });
  },

  // 問い合せ一覧の取得
  async getPhoneCustomerMatterList(customerPhoneNum1,customerPhoneNum2,phoneNumberFlg, page, sortBy, sortDesc) {
    // await sleep(500);
    return http.get('/get-matter-list', {
      params: {
        phone_number1: customerPhoneNum1,
        phone_number2: customerPhoneNum2,
        phone_number_flg: phoneNumberFlg,
        page: page,
        orderBy: sortBy,
        orderDir: sortDesc
      },
    });
  },
  async getEmailCustomerMatterList(email1,email2,emailFlg, page, sortBy, sortDesc) {
    // await sleep(500);
    return http.get('/get-matter-list', {
      params: {
        email1: email1,
        email2: email2,
        email_flg: emailFlg,
        page: page,
        orderBy: sortBy,
        orderDir: sortDesc
      },
    });
  },
  async getLastNameCustomerMatterList(customerLastName, page, sortBy, sortDesc) {
    // await sleep(500);
    return http.get('/get-matter-list', {
      params: {
        last_name: customerLastName,
        page: page,
        orderBy: sortBy,
        orderDir: sortDesc
      },
    });
  },
  async getCompanyCustomerMatterList(companyName, page, sortBy, sortDesc) {
    // await sleep(500);
    return http.get('/get-matter-list', {
      params: {
        company_name: companyName,
        page: page,
        orderBy: sortBy,
        orderDir: sortDesc
      },
    });
  },
  // 全問い合わせ検索
  async searchAllMatters() {
    return {
      data: dummyMatterList,
    };
  },

  // ユーザーマスタの取得
  async getUserMaster() {
    return http.get('/user-search', {params: { matter_assignable: 1,orderBy:'matter_assignable_order',orderDir:'asc'}});
  },

  // ログインユーザーの情報を取得
  async getMyInfo() {
    return http.get('/my-info');
  },
};

const dummyMatterList = [
  {
    matterId: 1,
    matterNumber: '220110-000001',
    idPerDate: 1,
    inquiryType: 0,
    inquiryDate: '2022/01/10',
    userId: 1,
    status: 3,
    customerFirstName: '桃子',
    customerLastName: '渚',
    customerCompany: '有限会社 青山',
    customerPhoneNum1: '056-588-0478',
    customerPhoneNum2: '0600-527-596',
    matterTitle: 'title1',
    createdAt: '2022-01-10 11:16:14',
    updatedAt: '2022-02-01 20:11:46',
    inquiryTypeName: 'メール',
    statusName: '対応済み',
    customerName: '渚 桃子',
  },
  {
    matterId: 2,
    matterNumber: '220110-000002',
    idPerDate: 2,
    inquiryType: 0,
    inquiryDate: '2022/01/10',
    userId: 1,
    status: 1,
    customerFirstName: '和也',
    customerLastName: '井上',
    customerCompany: '株式会社 笹田',
    customerPhoneNum1: '080-5918-3579',
    customerPhoneNum2: '04-1377-6291',
    matterTitle: 'title2',
    createdAt: '2022-01-10 11:16:14',
    updatedAt: '2022-01-27 14:03:56',
    inquiryTypeName: 'メール',
    statusName: '対応中',
    customerName: '井上 和也',
    matterUrgency:null
  },
  {
    matterId: 3,
    matterNumber: '220110-000003',
    idPerDate: 3,
    inquiryType: 0,
    inquiryDate: '2022/01/10',
    userId: 1,
    status: 0,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: 'title3',
    createdAt: '2022-01-10 11:16:14',
    updatedAt: '2022-01-10 11:16:14',
    inquiryTypeName: 'メール',
    statusName: '未対応',
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 4,
    matterNumber: '220111-000001',
    idPerDate: 1,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:04:49',
    updatedAt: '2022-01-11 02:04:49',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 5,
    matterNumber: '220111-000002',
    idPerDate: 2,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:17:45',
    updatedAt: '2022-01-11 02:17:45',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 6,
    matterNumber: '220111-000003',
    idPerDate: 3,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:18:18',
    updatedAt: '2022-01-11 02:18:18',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 7,
    matterNumber: '220111-000004',
    idPerDate: 4,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:19:09',
    updatedAt: '2022-01-11 02:19:09',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 8,
    matterNumber: '220111-000005',
    idPerDate: 5,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:19:30',
    updatedAt: '2022-01-11 02:19:30',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 9,
    matterNumber: '220111-000006',
    idPerDate: 6,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:23:31',
    updatedAt: '2022-01-11 02:23:31',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 10,
    matterNumber: '220111-000007',
    idPerDate: 7,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:23:59',
    updatedAt: '2022-01-11 02:23:59',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 11,
    matterNumber: '220111-000008',
    idPerDate: 8,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:29:51',
    updatedAt: '2022-01-11 02:29:51',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 12,
    matterNumber: '220111-000009',
    idPerDate: 9,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:30:30',
    updatedAt: '2022-01-11 02:30:30',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
  {
    matterId: 13,
    matterNumber: '220111-000010',
    idPerDate: 10,
    inquiryType: 1,
    inquiryDate: '2022/01/11',
    userId: null,
    status: null,
    customerFirstName: null,
    customerLastName: null,
    customerCompany: null,
    customerPhoneNum1: null,
    customerPhoneNum2: null,
    matterTitle: null,
    createdAt: '2022-01-11 02:34:46',
    updatedAt: '2022-01-11 02:34:46',
    inquiryTypeName: '電話',
    statusName: null,
    customerName: null,
    matterUrgency:null
  },
];
