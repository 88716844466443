var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-3",attrs:{"color":"grey lighten-5","id":"matter-list-search-table"}},[(_vm.inquiryHistoryFlg)?_c('v-data-table',{staticClass:"elevation-4 mx-3",attrs:{"headers":_vm.headers,"items":_vm.matterList,"single-select":_vm.singleSelect,"show-select":"","item-key":"matterId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","item-class":_vm.itemRowColorText,"no-data-text":"テスト"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.$store.state.auth.user.isViewer && !_vm.$store.state.auth.user.isGuest)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","disabled":!_vm.isDeleted},on:{"click":function($event){return _vm.confirmDelete()}}},'v-btn',attrs,false),on),[_vm._v(" 削除 ")]):_vm._e()]}}],null,false,311218379),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("削除してもよろしいでしょうか。")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-7",attrs:{"rounded":"","color":"primary","large":""},on:{"click":_vm.deleteMatters}},[_vm._v("はい")]),_c('v-btn',{staticClass:"px-5",attrs:{"rounded":"","color":"warning","large":""},on:{"click":_vm.closeDelete}},[_vm._v("キャンセル")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true}],null,false,2376679157),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_c('v-data-table',{staticClass:"elevation-4 mx-3",attrs:{"headers":_vm.headers,"items":_vm.matterList,"single-select":"","item-value":function (item) { return item; },"show-select":!_vm.$store.state.auth.user.isViewer && !_vm.$store.state.auth.user.isGuest,"item-key":"matterId","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"item-class":_vm.itemRowColorText,"no-data-text":"検索条件に一致するお問い合わせはありません"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"dblclick:row":_vm.inquiryHandleTransition},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',[_c('v-col',{staticClass:"d-flex items-center",staticStyle:{"height":"64px","padding":"0px","min-width":"500px"},attrs:{"cols":"4"}},[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.$store.state.auth.user.isViewer && !_vm.$store.state.auth.user.isGuest)?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","disabled":!_vm.isDeleted},on:{"click":function($event){return _vm.confirmDelete()}}},'v-btn',attrs,false),on),[_vm._v(" 削除 ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex items-center",staticStyle:{"width":"200px"},attrs:{"cols":"6"}},[_c('div',{staticClass:" items-center justify-center"},[_c('p',{staticClass:"text-subtitle-1 mt-2"},[_vm._v(" 表示件数:")])]),_c('div',{staticStyle:{"width":"50%"}},[_c('v-select',{staticClass:"square ml-2",attrs:{"value":_vm.itemsPerPage,"items":_vm.listRowLimit,"label":"","solo":"","flat":"","outlined":"","dense":""},on:{"change":function($event){return _vm.$emit('changePerPage', $event)}}})],1)])],1)],1)]}}]),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("削除してもよろしいでしょうか。")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-7",attrs:{"rounded":"","color":"primary","large":""},on:{"click":_vm.deleteMatters}},[_vm._v("はい")]),_c('v-btn',{staticClass:"px-5",attrs:{"rounded":"","color":"warning","large":""},on:{"click":_vm.closeDelete}},[_vm._v("キャンセル")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.matterTitle",fn:function(ref){
var item = ref.item;
return [_c('tooltip-item-table',{attrs:{"tooltip-text":item.matterTitle}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.matterList.length > 0),expression:"matterList.length > 0"}],staticClass:"text-center py-1"},[_c('v-pagination',{attrs:{"value":_vm.page,"length":_vm.pageCount,"total-visible":"7"},on:{"input":function($event){return _vm.$emit('changePage', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }