<template>
    <!-- <v-container class="grey lighten-3"> -->
    <v-card class="ma-1">
        <v-card-title>
            <v-row align="center">
                <!-- タイトル -->
                <v-col cols="4" class="py-0">
                  <v-row>
                      <v-subheader class="text-h6 mb-0 ml-5 pr-0">件名<span style="color: red;">*</span>：</v-subheader>
                      <v-text-field
                        v-model="matterTitle"
                        solo
                        class="text-h6 font-weight-bold "
                        hide-details
                        flat
                        :outlined="outLineFlg"
                        :disabled="readonlyFlg"
                        @focus="outLineOn"
                        @blur="outLineOff"
                        :background-color="getBackgroundColor(matterStatusData.title, matterTitle)"
                      ></v-text-field>
                      <!-- {{ matterTitle }} -->
                  </v-row>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field
                        :value="matterStatusData.matterNumber"
                        label="案件番号"
                        dense
                        outlined
                        hide-details
                        :disabled="true"
                    ></v-text-field>
                </v-col>
                <!-- 緊急度 -->
                <v-col cols="2" class="py-0">
                  <v-select
                      v-model="urgencyFlg"
                      :items="urgencyList"
                      item-text="urgencyName"
                      item-value="urgencyFlg"
                      label="緊急度"
                      dense
                      outlined
                      hide-details
                      :disabled="readonlyFlg"
                      :background-color="getBackgroundColor(matterStatusData.urgencyFlg, urgencyFlg)"
                  ></v-select>
                </v-col>
                <!-- 対応状況 -->
                <v-col cols="2" class="py-0">
                    <v-select
                        v-model="statusName"
                        :items="statusList"
                        item-text="statusName"
                        label="状態"
                        @change="updateStatusFlg"
                        dense
                        outlined
                        hide-details
                        :disabled="readonlyFlg"
                        :background-color="getBackgroundColor(matterStatusData.statusFlg, statusFlg)"
                    ></v-select>
                </v-col>
                <!-- ユーザーリスト -->
                <v-col cols="2" class="py-0">
                    <v-select
                        v-model="userName"
                        :items="userList"
                        item-text="fullName"
                        label="担当者"
                        @change="updateUserId"
                        dense
                        outlined
                        hide-details
                        :disabled="readonlyFlg"
                        :background-color="getBackgroundColor(matterStatusData.userId, userId)"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text class="py-1">
            <v-row align-content="center" class="mt-0">
                <v-spacer cols="1" />
                <!-- 初回問い合わせ・最終回答 -->
                <v-col cols="7">
                    <v-row align-content="start">
                      <v-col cols="3" class="py-0 pl-8">
                        <p class="text-subtitle-1 mb-0">受付窓口</p>
                      </v-col>
                      <v-col cols="9" class="py-0 px-0">
                        <v-radio-group
                          v-model="serveFlagState"
                          @change="handleServeFlagChange"
                          row
                          hide-details
                          class="mt-0 pt-0"
                          :disabled="readonlyFlg"
                          :background-color="getBackgroundColor(matterStatusData.server_flg, serveFlagState)"
                        >
                          <v-radio
                            v-for="state in serveFlagStateList"
                            :key="state.value"
                            :label="state.label"
                            :value="state.value"
                            class="mr-2"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="py-1 pl-8">
                        <p class="mb-0 text-subtitle-1">受付手段<span style="color: red;">*</span></p>
                      </v-col>
                      <v-col cols="9" class="py-1 px-0">
                        <v-radio-group
                            v-model="inquiryType"
                            row
                            hide-details
                            class="mt-0 pt-0"
                            :disabled="readonlyFlg"
                            :background-color="getBackgroundColor(matterStatusData.inquiryType, inquiryType)"
                        >
                          <v-radio
                              v-for="method in inquiryTypeList"
                              :key="method.value"
                              :label="method.label"
                              :value="method.value"
                              class="mr-3"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row align-content="start">
                        <v-col cols="3" class="py-0 pl-8">
                            <p class="text-subtitle-1 mb-0">対応開始</p>
                        </v-col>
                        <v-col cols="auto" class="py-0 px-0">
                            <p class="text-subtitle-1 mb-0">{{ firstInquiryTime }}</p>
                        </v-col>
                    </v-row>
                    <v-row align-content="start">
                        <v-col cols="3" class="py-0 pl-8">
                            <p class="text-subtitle-1 mb-0">対応終了</p>
                        </v-col>
                        <v-col cols="auto" class="py-0 px-0">
                            <p class="text-subtitle-1 mb-0" v-if="matterStatusData.completion_time">{{matterStatusData.completion_time.replaceAll('-','/')}}</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="5">
                  <v-row>
                    <v-col cols="2" class="py-0">
                      <p class="mb-0 text-subtitle-1">大区分<span style="color: red;">*</span></p>
                    </v-col>
                    <v-col cols="auto" class="py-0">
                      <v-radio-group
                        v-model="categoryTypeFlg"
                        row
                        hide-details
                        class="mt-0 pt-0"
                        :disabled="readonlyFlg"
                        :background-color="getBackgroundColor(matterStatusData.categoryTypeFlg, categoryTypeFlg)"
                      >
                        <v-radio
                          v-for="category in categoryList"
                          :key="category.categoryFlg"
                          :label="category.categoryName"
                          :value="category.categoryFlg"
                          class="mr-3"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" align="center">
                    <v-col cols="2" class="py-0">
                      <p class="mb-0 text-subtitle-1">小区分<span style="color: red;">*</span></p>
                    </v-col>
                    <v-col cols="4" class="py-0">
                      <v-select
                        v-model="subTypeName"
                        :items="filteredSubTypeList"
                        item-text="subTypeName"
                        @change="updateSubTypeFlg"
                        dense
                        outlined
                        hide-details
                        :disabled="readonlyFlg"
                        :menu-props="{maxHeight: 500}"
                        :background-color="getBackgroundColor(matterStatusData.subTypeFlg, subTypeFlg)"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="py-0 pl-0 d-flex align-center justify-space-between">
                      <v-switch  v-if="!$store.state.auth.user.isViewer  && !$store.state.auth.user.isGuest"
                                 v-model="checkedFlg"
                                 label="チェック"
                                 class="pt-0"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row align-content="center" align="center">
                    <v-col cols="2" class="py-0">
                      <p class="mb-0 text-subtitle-1">集計用</p>
                    </v-col>
                    <v-col cols="4" class="py-0 mb-2">
                      <v-select
                        v-model="aggregateTypesName"
                        :items="filteredAggregateTypesList"
                        item-text="aggregateTypesName"
                        @change="updateaggregateTypesFlg"
                        dense
                        outlined
                        hide-details
                        clearable
                        @click:clear="onClearAggregateTypesFlg"
                        :disabled="readonlyFlg"
                        :menu-props="{maxHeight: 500}"
                        :background-color="getBackgroundColor(matterStatusData.aggregateTypesFlg, aggregateTypesFlg)"
                      ></v-select>
                    </v-col>
                    <v-col cols="6" class="py-0 pl-0 d-flex align-center justify-space-between">
                      <div class="d-flex">
                        <v-checkbox
                          v-model="defectFlg"
                          :background-color="getBackgroundColor(matterStatusData.defectFlg == 1, defectFlg)"
                          label="不良・不具合"
                          class="mr-2 rounded-v-checkbox">
                        </v-checkbox>
                        <v-checkbox
                          v-model="requestFlg"
                          :background-color="getBackgroundColor(matterStatusData.requestFlg == 1, requestFlg)"
                          label="要望"
                          class="mr-1 rounded-v-checkbox">
                        </v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="mt-5 mb-2 pt-0">
                <v-col cols="7">
                  <v-row class="align-content-start" v-show="!historyModalFlg">
                    <v-col cols="2" class="py-0">
                      <v-btn
                        rounded
                        large
                        :color="hasShippingData?'#632523':'#d99694'"
                        class="px-8 text-subtitle-1 mb-3 white--text ml-5"
                        v-if="!readonlyFlg || hasShippingData"
                        @click="$emit('openShippingModal')"
                        :disabled="!(matterProductData && matterProductData.id) || !(matterCustomerData && matterCustomerData.id)"
                      >{{ hasShippingData?'発送票発行':'発送票入力'}}
                      </v-btn>
                    </v-col>
                    <v-col cols="8" class="py-0">
                      <v-row align-content="center" align="center" class="mb-0" v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest">
                        <v-col cols="5">
                          <p class="mb-0 mt-2 text-subtitle-1 float-right">ND表示電話番号</p>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider v-slot="{ errors }" rules="phoneNumber">
                            <v-text-field
                              v-model="ndDisplayTel"
                              ref="inputClear"
                              placeholder="例：09012345678 (半角･ハイフンなし)"
                              style="margin-top: 4px;"
                              outlined
                              hide-details="auto"
                              dense
                              :clearable="!readonlyFlg"
                              :readonly="readonlyFlg"
                              :background-color="getBackgroundColor(matterStatusData.ndDisplayTel, ndDisplayTel)"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="1" class="pl-0">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon
                                :disabled="!ndDisplayTel || ndDisplayTel.length==0"
                                v-if="!readonlyFlg"
                                v-bind="attrs"
                                v-on="on"
                                @click="$emit('copy-nd-display-tel', ndDisplayTel)">
                                <v-icon>mdi-content-copy</v-icon>
                              </v-btn>
                            </template>
                            <span>電話番号１にコピーする</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5">
                      <v-row class="justify-end" v-show="!historyModalFlg">
                        <v-switch  v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest"
                          v-model="unannounced"
                          label="非通知"
                          class="pt-0"
                          @change="$emit('unannouncedSwitchEvent',unannounced)"
                        ></v-switch>
                        <v-spacer></v-spacer>
                        <!-- 状態を保存 -->
                        <v-btn
                            rounded
                            large
                            color="success"
                            class="px-6 text-subtitle-1 mr-2 mt-1"
                            :disabled="readonlyFlg"
                            @click="statusSave(matterId, updateStatusInfo)"
                        >保存</v-btn>
                        <v-btn
                            rounded
                            large
                            color="primary"
                            class="px-6 text-subtitle-1 mr-2 mt-1"
                            :disabled="readonlyFlg"
                            @click="statusCompleted(matterId)"
                        >対応完了</v-btn>
                        <!-- 問い合せを閉じる -->
                        <v-btn
                            rounded
                            large
                            color="warning"
                            class="px-6 text-subtitle-1 mr-2 mt-1"
                            @click="validItemCheck"
                        >問い合わせを閉じる</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- ローディング中にスピナーを表示させる -->
        <v-overlay :value="loadingCount > 0" >
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>

        <!-- 問い合せを閉じるボタンを押した時に修正中の項目があればモーダルを表示 -->
<!--        <v-dialog v-model="inquiryCloseFlg" scrollable max-width="95%">-->
<!--            <InquiryStatusInquiryCloseModal-->
<!--                :customer-different-data-list="customerDifferentDataList"-->
<!--                :status-different-data-list="statusDifferentDataList"-->
<!--                @modal-close-button-click="modalClose"-->
<!--                @matter-close-button-click="matterClose"-->
<!--            />-->
<!--        </v-dialog>-->
      <!-- 問い合せを閉じるボタンを押した時に修正中の項目があればモーダルを表示 -->
        <v-dialog v-model="inquiryCloseFlg" scrollable max-width="95%">
            <InquiryStatusInquiryCheckValidAndCloseModal
                :nullStatusDataList="nullStatusDataList"
                @modal-close-button-click="modalClose"
                @matter-close-button-click="matterClose"
            />
        </v-dialog>
    </v-card>
    <!-- </v-container> -->
</template>

<script>
import getMatterService from '@/services/getMatterService.js';
import updateMatterService from '@/services/updateMatterService.js';
// import InquiryStatusInquiryCloseModal from './InquiryStatusInquiryCloseModal.vue';
import InquiryStatusInquiryCheckValidAndCloseModal from './InquiryStatusInquiryCheckValidAndCloseModal.vue';
import moment from 'moment';
import { ValidationProvider, localize, extend } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import {getBackgroundColor} from '@/components/helpers/utils';
import subtypeServices from "@/services/subtypeServices";
import aggregateTypesServices from "@/services/aggregateTypesServices";
import constant from '@/components/helpers/const';

localize('ja', ja);

extend('phoneNumber', {
  // エラーメッセージを設定する
  message: '有効な電話番号を入力してください(半角数字･ハイフンなし)',
  // /^0[1-9][0-9]{0,4}-[0-9]{1,5}-[0-9]{1,5}$/
  validate(value) {
    if (value.match(/^0[1-9][0-9]{8,9}$/)) {
      return true;
    }
  }
});


export default {
    components: {
        // InquiryStatusInquiryCloseModal,
        InquiryStatusInquiryCheckValidAndCloseModal,
        ValidationProvider
    },
    props: {
        // 親コンポーネントよりバックエンドapi取得_案件データ(basicInfo)を取得
        matterStatusData: {
            type: [String, Number, Object]
        },
        matterInquiriesData: {
            type: [Array,String, Number, Object]
        },
        matterId: {
            type: Number,
        },
        customerPhoneNumber1: {
            type: String,
        },
        readonlyFlg: {
            type: Boolean,
        },
        // お客様情報パネルから取得した保存されていない変更項目リストを取得
        customerDifferentDataList: {
            type: [String, Number, Object, Array]
        },
        // 問い合わせ履歴モーダル
        historyModalFlg: {
            type: Boolean,
        },
        matterCustomerData: {
          type: Object
        },
        matterProductData: {
          type: Object
        },
        hasShippingData:{
          type: Boolean,
        },
    },
    data() {
        return {
            // バックエンドapi取得_案件担当者の情報を格納
            matterUserInfo: '',
            // バックエンドapi取得_担当者一覧を格納
            userList: [],

            // 案件タイトル
            matterTitle: '',
            outLineFlg: false,
            // 状態
            statusFlg: '',
            statusName: '',
            // 担当者
            userId: this.$store.state.auth.user.id,
            userName: this.$store.state.auth.user.fullName,
            // 初回問い合わせ
            firstInquiryTime: '',
            // 最終回答
            lastInquiryTime: '',
            // loading時間
            loadingCount: 0,

            // 状態リスト
            statusList: [
                {
                    statusFlg: 0,
                    statusName: '未対応',
                },
                {
                    statusFlg: 1,
                    statusName: '対応中',
                },
                {
                    statusFlg: 2,
                    statusName: '返送待ち',
                },
                {
                    statusFlg: 3,
                    statusName: '商品手配中',
                },
                {
                    statusFlg: 4,
                    statusName: '対応済み',
                },
                {
                    statusFlg: 6,
                    statusName: '他部署案内',
                },
                {
                    statusFlg: 7,
                    statusName: '他部署依頼',
                },
                {
                    statusFlg: 5,
                    statusName: '未達完了',
                },
            ],
            // 問い合せを閉じる時にモーダルを表示する場合のフラグ
            inquiryCloseFlg: false,
            checkedFlg: false,

            // DBの値と現在の値が違う項目のリスト
            // statusDifferentDataList: [],
            nullStatusDataList: [],
            //category type
            categoryList: [
              {
                categoryFlg: 1,
                categoryName: 'お問合せ',
              },
              {
                categoryFlg: 2,
                categoryName: '苦情',
              },
              {
                categoryFlg: 4,
                categoryName: 'その他',
              },
            ],
            categoryTypeFlg: null,
            //subType
            subTypeList: [],
            //Aggregate Types
            aggregateTypesList: [],
            //urgency
            urgencyList: [
              {
                urgencyFlg: 2,
                urgencyName: '中',
              },
              {
                urgencyFlg: 1,
                urgencyName: '高',
              },
              {
                urgencyFlg: 3,
                urgencyName: '指定なし',
              },
            ],
            // Reception method
            inquiryTypeList: constant.inquiryTypeList,
            // Correspondence method
            inquiryType: null,
            subTypeFlg: '',
            subTypeName: '',
            aggregateTypesFlg: '',
            aggregateTypesName: '',
            urgencyFlg: null,
            shippingModal: false,
            ndDisplayTel: '',
            mountCompletion: false,
            unannounced: false,
            serveFlagStateList: [
              { value: 0, label: 'お客様相談室' },
              { value: 1, label: 'オフィシャルショップ' },
              { value: 2, label: 'Shachihata ID' },
              { value: 3, label: 'メールオーダー' },
            ],
            serveFlagState: 0,
            serveFlagStateChange: false,
            defectFlg: false,
            requestFlg: false,
        }
    },
    watch:{
      customerPhoneNumber1(val){
        if (val && val === '非通知' || val && val.toLowerCase() === 'unknown'){
          this.unannounced = true
        }else {
          this.unannounced = false
        }
      },
      matterTitle(){
        this.$store.dispatch('updateMatterTitleAction', { matterId: this.matterId, matterTitle: this.matterTitle })
      },
      urgencyFlg(){
        this.$store.dispatch('updateMatterUrgencyAction', { matterId: this.matterId, matterUrgency: this.urgencyFlg })
      },
      $data: {
        handler(val){
          if (this.mountCompletion){
            this.saveState(val)
          }
        },
        deep: true
      },
      $route() {
        this.mountCompletion = true;
        this.restoreState();
      },
      matterId: function(){
        this.mountCompletion = false;
      },
      ndDisplayTel(){
        this.$emit('nd-display-tel-search', this.ndDisplayTel);
      }
    },
  created() {
    this.serveFlagState = this.matterStatusData.server_flg ? this.matterStatusData.server_flg : 0;
    subtypeServices.getSubtypeList().then(Response=>{
      Response.data.data.forEach(data =>
          this.subTypeList.push({subTypeFlg: data.id ,subTypeName: data.name, serverFlg: data.server_subtype}));
    });
    aggregateTypesServices.getAggregateTypesList().then(Response=>{
      Response.data.data.forEach(data =>
          this.aggregateTypesList.push({aggregateTypesFlg: data.id ,aggregateTypesName: data.name, serverFlg: data.server_type}));
    });
  },
  methods: {
        getBackgroundColor,
        restoreState(){
          if(this.matterStatusData.server_flg == undefined){
            this.matterStatusData.server_flg = 0;
          }
          this.serveFlagState = this.matterStatusData.server_flg ? this.matterStatusData.server_flg : 0;
          let matterState = this.$store.getters.getMatterState(this.matterStateKey);
          matterState.mountCompletion = true;
          Object.assign(this.$data, matterState);
        },
        saveState(data){
          this.$store.dispatch('saveMatterStateAction', { matterId: this.matterId, key: this.matterStateKey, data })
        },
        // タイトルのアウトラインの切り替え処理
        outLineOn() {
            this.outLineFlg = true;
        },
        outLineOff() {
            this.outLineFlg = false;
            sessionStorage.setItem(`matterTitle_${this.matterId}`, JSON.stringify({ matterId: this.matterId, matterTitle: this.matterTitle }))
        },

        // statusFlgを更新(セレクトボックスで指定)
        updateStatusFlg(event) {
            const status = this.statusList.filter(status => status.statusName === event);
            this.statusFlg = status[0].statusFlg;
        },
        updateSubTypeFlg(event) {
          const subType = this.subTypeList.filter(subType => subType.subTypeName === event);
          this.subTypeFlg = subType[0].subTypeFlg;
        },
        updateaggregateTypesFlg(event) {
          const aggregateType = this.aggregateTypesList.filter(itemType => itemType.aggregateTypesName === event);
          this.aggregateTypesFlg = aggregateType[0].aggregateTypesFlg;
        },
        onClearAggregateTypesFlg() {
          this.aggregateTypesFlg = '';
        },
        statusCompleted(matterId) {
          const now = new Date();
          const basicInfo = this.updateStatusInfo;
          basicInfo.completion_time = now.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
          this.statusSave(matterId, basicInfo);
        },
        // userIdを更新(セレクトボックスで指定)
        updateUserId(event) {
            const user = this.userList.filter(user => user.fullName === event);
            this.userId = user[0].id;
        },

        // バックエンドから取得した日付データのフォーマットを変更する(「yyyy-mm-ddT00:00:00.000000Z」→「yyyy/mm/dd」)
        dateFormatChange(date) {
          if (date){
            const newDate = new Date(date);
            const year = newDate.getFullYear();
            const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
            const day = ("00" + newDate.getDate()).slice(-2);
            const hours = ("00" + newDate.getHours()).slice(-2);
            const minutes = ("00" + newDate.getMinutes()).slice(-2);
            const seconds = ("00" + newDate.getSeconds()).slice(-2);

            return year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds
          }else{
           return '';
          }
        },
        // statusFlgからstatusNameを取得する
        getStatusName(statusFlg) {
            const statusList = this.statusList.filter(status => status.statusFlg === statusFlg);
            return statusList[0].statusName;
        },
        // Get subTypeName from subTypeFlg
        getSubTypeName(subTypeFlg) {
            const subTypeList = this.subTypeList.filter(subType => subType.subTypeFlg === subTypeFlg);
            return subTypeList.length?subTypeList[0].subTypeName:'';
        },
        // Get subTypeName from aggregateTypesFlg
        getAggregateTypesName(aggregateTypesFlg) {
            const aggregateTypesList = this.aggregateTypesList.filter(itemType => itemType.aggregateTypesFlg === aggregateTypesFlg);
            return aggregateTypesList.length ? aggregateTypesList[0].aggregateTypesName : '';
        },

        // 状態を保存ボタンクリック時
        statusSave: async function (matterId, basicInfo) {
            this.loadingCount++;
            try {
              basicInfo.server_flg = this.serveFlagState;
              if(matterId > 0){
                if (!basicInfo.userId){
                  basicInfo.userId = this.$store.state.auth.user.id;
                }
                // DB更新
                await updateMatterService.updateMatterStatusData(
                    matterId,
                    basicInfo,
                ).then(async (response)=>{
                  // 取得(更新)したデータを格納
                  const newMatterStatusData = response.data.data.basicInfo;
                  if (this.matterStatusData.server_flg !== newMatterStatusData.server_flg) {
                    this.$store.dispatch('removeMatterListAction', matterId);
                    this.$store.dispatch('updateNavbarMatterListAction', {
                      matterId: matterId,
                      matterTitle: newMatterStatusData.title,
                      matterLink: `/inquiryhandle/${matterId}`,
                      matterPhone: newMatterStatusData.inquiryType ? true : false,
                      matterUrgency: newMatterStatusData.urgencyFlg,
                      serverFlag: newMatterStatusData.server_flg
                    });
                  }
                  // 日付データのフォーマットを変更する
                  newMatterStatusData.oldMemo = newMatterStatusData.memo;
                  newMatterStatusData.oldProductLot = newMatterStatusData.productLot;
                  // 取得(更新)したデータで親コンポーネントの「matterStatusData」を更新する
                  this.$emit('update:matterStatusData', newMatterStatusData);
                  // 担当者情報を更新する
                  if (newMatterStatusData.userId !== null) {
                    await this.getMatterUser(newMatterStatusData.userId);
                  }

                newMatterStatusData.matterId = matterId;
                newMatterStatusData.firstInquiryTime = this.dateFormatChange(newMatterStatusData.firstInquiryTime);
                newMatterStatusData.lastInquiryTime = this.dateFormatChange(newMatterStatusData.lastInquiryTime);
                newMatterStatusData.completion_time = this.dateFormatChange(newMatterStatusData.completion_time);

                  // 取得(更新)したデータを各変数に代入
                  this.matterStatusDataAssign(newMatterStatusData);
                  // VuexのnavbarMatterListsのmatterTitleを更新（ナビゲーションバーに表示している案件タイトルを更新）
                  await this.$store.dispatch('updateMatterTitleAction', { matterId: matterId, matterTitle: newMatterStatusData.title });
                  this.$emit('update:matterStatus', newMatterStatusData);
                }).catch(error=>{
                  if(error.response.status === 403){
                    const editing_by = error.response.data.message.editing_by;
                    this.$emit('isEditing',editing_by);
                  }
                });
              }else{
                basicInfo.firstInquiryTime = moment(new Date(this.matterStatusData.firstInquiryTime)).format("YYYY-MM-DD HH:mm:ss");
                const response = await updateMatterService.createMatterStatusData(
                    basicInfo,
                );
                // 取得(更新)したデータを格納
                const newMatter = response.data.data;
                this.$emit('update:matterStatus', newMatter);
              }
            } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
        },
        // 問い合わせを閉じるボタンクリック時
        // fixItemCheck() {
        //     // DBの値と現在の値が違う項目のリストを作成
        //     this.updateStatusDifferentDataList();
        //     // 保存されていない変更項目があるか
        //     if (this.statusDifferentDataList.length > 0 || this.customerDifferentDataList.length > 0) {
        //         // 保存されていない変更項目があればモーダルを表示
        //         this.inquiryCloseFlg = true;
        //     } else {
        //         // 保存されていない変更項目がなければ問い合わせを閉じる
        //         this.matterClose()
        //     }
        // },

        validItemCheck() {
          if (this.readonlyFlg){
            this.matterClose()
          }else{
            // DBの値と現在の値が違う項目のリストを作成
            this.checkNullStatusDataList();
            // 保存されていない変更項目があるか
            if (this.nullStatusDataList.length > 0) {
              // 保存されていない変更項目があればモーダルを表示
              this.inquiryCloseFlg = true;
            } else {
              // 保存されていない変更項目がなければ問い合わせを閉じる
              this.matterClose()
            }
          }
        },
        // 問い合わせを閉じる処理
        async matterClose() {
          const serverFlg = this.$store.getters.getServerFlgByMatterId(this.matterId)
          // VuexのnewMatterListを更新（閉じるボタンを押したmatterをListから削除）
          this.$store.dispatch('removeMatterListAction', this.matterId);
          sessionStorage.removeItem(`matterTitle_${this.matterId}`);

          this.$store.dispatch('clearMatterStateAction', {matterId: this.matterId});
          if (this.$store.state.auth.user.isViewer || this.$store.state.auth.user.isGuest){
            // 問い合せ一覧へ画面遷移
            await this.$router.push('/all-matter-search')
          }else {
            // 問い合せ一覧へ画面遷移
            await this.$emit('closeEdit');
            switch (serverFlg){
              case constant.serverFlag.webcs:
                await this.$router.push('/webcs-matter-list')
                break;
              case constant.serverFlag.oshop:
                await this.$router.push('/oshop-matter-list')
                break;
              case constant.serverFlag.sid:
                await this.$router.push('/sid-matter-list')
                break;
              case constant.serverFlag.mo:
                await this.$router.push('/mo-matter-list')
                break;
              default:
                await this.$router.push('/webcs-matter-list')
                break;
            }
          }
        },

        // 問い合わせを閉じるボタンクリック時のモーダルを閉じる
        modalClose() {
            this.inquiryCloseFlg = false;
        },

        // ユーザーマスタの取得(全ユーザー取得)
        getUserMaster: async function () {
            try {
                const response = await getMatterService.getUserMaster();
                this.userList = response.data.data;
            } catch (e) {
                console.log(e);
            }
        },

        // 指定したuserIdの情報を取得
        getMatterUser: async function (userId) {
            try {
                const response = await getMatterService.getUserInfo(userId);
                this.matterUserInfo = response.data.data;
            } catch (e) {
                console.log(e);
            }
        },

        // api取得データを各変数に代入
        matterStatusDataAssign(matterStatusData) {
            const objectTitle = JSON.parse(sessionStorage.getItem(`matterTitle_${this.matterId}`))
            if(!objectTitle || objectTitle == {}) this.matterTitle = matterStatusData.title;
            else this.matterTitle = objectTitle.matterTitle
            // 状態
            this.statusFlg = matterStatusData.statusFlg;
            this.checkedFlg = matterStatusData.checkedFlg;
            this.defectFlg = matterStatusData.defectFlg == 1;
            this.requestFlg = matterStatusData.requestFlg == 1;
            this.statusName = this.getStatusName(matterStatusData.statusFlg);
            this.inquiryType = matterStatusData.inquiryType;
            // 担当者
            this.userId = matterStatusData.userId;
            if (this.matterUserInfo !== '') {
                this.userName = this.matterUserInfo.fullName;
            }
            // 初回問い合わせ時刻
            this.firstInquiryTime = matterStatusData.firstInquiryTime;
            // 最終回答時刻
            this.lastInquiryTime = matterStatusData.lastInquiryTime;
            this.categoryTypeFlg = matterStatusData.categoryTypeFlg;
            this.ndDisplayTel = matterStatusData.ndDisplayTel;
            this.subTypeFlg = matterStatusData.subTypeFlg;
            this.checkedFlg = matterStatusData.checkedFlg;
            this.subTypeName = this.getSubTypeName(matterStatusData.subTypeFlg);
            this.aggregateTypesFlg = matterStatusData.aggregateTypesFlg;
            this.aggregateTypesName = this.getAggregateTypesName(matterStatusData.aggregateTypesFlg)
            this.urgencyFlg = matterStatusData.urgencyFlg;
        },

        // ルートが変更された時に呼び出す関数(この関数がないと/inquiryhandle/1から/inquiryhandle/2に変わった時にコンポーネントが更新されない)
        reloadInquiryStatus: async function () {
            try {
                // 日付データのフォーマットを変更する
                this.matterStatusData.firstInquiryTime = this.dateFormatChange(this.matterStatusData.firstInquiryTime);
                this.matterStatusData.lastInquiryTime = this.dateFormatChange(this.matterStatusData.lastInquiryTime);
                // ユーザーマスタ取得
                await this.getUserMaster();
                // 問い合わせ担当者の情報取得
                if (this.matterStatusData.userId !== null) {
                    await this.getMatterUser(this.matterStatusData.userId);
                }
                // api取得データを各変数に代入
                this.matterStatusDataAssign(this.matterStatusData);
            } catch (e) {
                console.log(e);
            }
        },
        // DBの値と現在の値が違う項目のリストを作成
        // updateStatusDifferentDataList() {
        //     // 配列の要素を全て削除
        //     this.statusDifferentDataList.splice(0);
        //     if (this.matterStatusData.title !== this.matterTitle) {
        //         this.statusDifferentDataList.push(
        //             {
        //                 title: 'タイトル',
        //                 changeBackText: this.matterStatusData.title,
        //                 changeText: this.matterTitle
        //             });
        //     }
        //     if (this.matterStatusData.statusFlg !== this.statusFlg) {
        //         this.statusDifferentDataList.push(
        //             {
        //                 title: '状態',
        //                 changeBackText: this.getStatusName(this.matterStatusData.statusFlg),
        //                 changeText: this.statusName
        //             });
        //     }
        //     if (this.matterStatusData.userId !== this.userId) {
        //         this.statusDifferentDataList.push(
        //             {
        //                 title: '担当者',
        //                 changeBackText: this.matterUserInfo.fullName,
        //                 changeText: this.userName
        //             });
        //     }
        // }
        checkNullStatusDataList() {
          // 配列の要素を全て削除
            this.nullStatusDataList.splice(0);
            let findCorrespondenceInfo = this.matterInquiriesData.filter(item => item.correspondenceInfo !== null);
            let findContent= this.matterInquiriesData.filter(item => item.content !== null);
            let newContent = findContent.length !== 0 ? findContent[0].content : null;
            let newCorrespondenceInfo = findCorrespondenceInfo.length !== 0 ? findCorrespondenceInfo[0].correspondenceInfo : null;
            let checkingField = {
              '件名':this.matterTitle,
              '大区分':this.categoryTypeFlg,
              '小区分':this.subTypeFlg,
              '受付手段':this.inquiryType,
              '商品情報':this.matterProductData.id,
              '対応内容':newCorrespondenceInfo,
              '問い合わせ内容':newContent,
            }
            for (const key in checkingField){
              let value = checkingField[key];
              if (value === null  || value === undefined || value === '') {
                this.nullStatusDataList.push(
                  {
                    title: key,
                  });
              }
            }
          },
          handleServeFlagChange() {
            this.serveFlagStateChange = true;
          },
        },
    computed: {
        // 状態を保存時に渡す値
        updateStatusInfo() {
          let checkedNumber = null;
          this.checkedFlg ? checkedNumber = 1 : checkedNumber = null;
            return {
                title: this.matterTitle,
                statusFlg: this.statusFlg,
                userId: this.userId,
                memo: this.matterStatusData.memo,
                productLot: this.matterStatusData.productLot,
                categoryTypeFlg: this.categoryTypeFlg,
                subTypeFlg: this.subTypeFlg,
                aggregateTypesFlg: this.aggregateTypesFlg,
                checkedFlg: checkedNumber,
                defectFlg: this.defectFlg ? 1: null,
                requestFlg: this.requestFlg ? 1: null,
                urgencyFlg: this.urgencyFlg,
                inquiryType: this.inquiryType,
                ndDisplayTel: this.ndDisplayTel,
            }
        },
        matterStateKey(){
          return `matter_state_${this.matterId}_inquiry_status`;
        },
        filteredSubTypeList() {
          return this.subTypeList.filter(subType => {
            const serverFlags = subType.serverFlg ? [...subType.serverFlg].map(Number).filter(num => !isNaN(num)) : [];
            if (this.serveFlagStateChange) {
              return serverFlags.includes(this.serveFlagState);
            } else {
              return subType.subTypeFlg == this.subTypeFlg || serverFlags.includes(this.serveFlagState)
            }
          });
        },
        filteredAggregateTypesList() {
          return this.aggregateTypesList.filter(item => {
            const serverFlags = item.serverFlg ? [...item.serverFlg].map(Number).filter(num => !isNaN(num)) : [];
            if (this.serveFlagStateChange) {
              return serverFlags.includes(this.serveFlagState);
            } else {
              return item.aggregateTypesFlg == this.aggregateTypesFlg || serverFlags.includes(this.serveFlagState);
            }
          });
        },
    },
    async mounted() {
        // 日付データのフォーマットを変更する
        this.matterStatusData.firstInquiryTime = this.dateFormatChange(this.matterStatusData.firstInquiryTime);
        this.matterStatusData.lastInquiryTime = this.dateFormatChange(this.matterStatusData.lastInquiryTime);
        // ユーザーマスタ取得
        await this.getUserMaster();
        // 問い合わせ担当者の情報取得
        if (this.matterStatusData.userId !== null) {
            await this.getMatterUser(this.matterStatusData.userId);
        }
        // api取得データを各変数に代入
        this.matterStatusDataAssign(this.matterStatusData);

        this.mountCompletion = true
        this.restoreState();
    }
}
</script>
<style scoped>
.v-select.v-input--dense .v-select__selection--comma {
    margin: 0px !important;
}
</style>

<style lang="scss">
  .rounded-v-checkbox {
    .v-input--selection-controls__input {
      .mdi-checkbox-blank-outline::before {
        content:"\F043D" !important;
      }
      .mdi-checkbox-marked::before {
        content:"\F043E" !important;
      }
    }
  }
</style>
