const inquiryTypeList = [
  {
    value: 1,
    label: '電話',
  },
  {
    value: 0,
    label: 'メール',
  },
  {
    value: 2,
    label: '郵送',
  },
  {
    value: 3,
    label: '来社',
  },
  {
    value: 4,
    label: '他部署転送',
  },
  {
    value: 5,
    label: 'その他',
  }
];

//todo settings export CSV

const allExportSettings = [
  {
    field: "id",
    display_name: "id"
  },
  {
    field: "matter_number",
    display_name: "案件番号"
  },
  {
    field: "ex_matter_number",
    display_name: "NO"
  },
  {
    field: "sid_matter_number",
    display_name: "SIDお問い合わせ番号"
  },
  {
    field: "status_flg",
    display_name: "状態"
  },
  {
    field: "category",
    display_name: "大区分"
  },
  {
    field: "subcategory",
    display_name: "中分類"
  },
  {
    field: "sub_type",
    display_name: "小区分"
  },
  {
    field: "aggregate_type",
    display_name: "集計用"
  },
  {
    field: "completion_time",
    display_name: "完了日時"
  },
  {
    field: "inquiry_type",
    display_name: "お問い合わせ方法"
  },  {
    field: "return_method",
    display_name: "回答方法"
  },
  {
    field: "title",
    display_name: "案件名"
  },  {
    field: "nd_display_tel",
    display_name: "ND表示電話番号"
  },
  {
    field: "last_name",
    display_name: "姓"
  },
  {
    field: "first_name",
    display_name: "名"
  },
  {
    field: "last_name_kana",
    display_name: "姓(よみがな)"
  },
  {
    field: "first_name_kana",
    display_name: "名(よみがな)"
  },
  {
    field: "email1",
    display_name: "メールアドレス１"
  },
  {
    field: "email2",
    display_name: "メールアドレス２"
  },
  {
    field: "phone_number1",
    display_name: "電話１"
  },
  {
    field: "phone_number2",
    display_name: "電話２"
  },
  {
    field: "customer_type_flg",
    display_name: "お客様種別"
  },
  {
    field: "company_name",
    display_name: "会社名"
  },
  {
    field: "company_name_kana",
    display_name: "会社名(よみがな)"
  },
  {
    field: "gender",
    display_name: "性別"
  },
  {
    field: "age_group",
    display_name: "年齢層"
  },
  {
    field: "industry_name",
    display_name: "業種名"
  },
  {
    field: "department_name",
    display_name: "部署名"
  },
  {
    field: "postal_code",
    display_name: "郵便番号"
  },
  {
    field: "prefecture_name",
    display_name: "都道府県"
  },
  {
    field: "address",
    display_name: "住所"
  },
  {
    field: "fax_number",
    display_name: "FAX"
  },
  {
    field: "retailer_name",
    display_name: "購入先"
  },
  {
    field: "remarks",
    display_name: "備考"
  },
  {
    field: "product_id",
    display_name: "商品ID"
  },
  {
    field: "created_by",
    display_name: "作成者"
  },
  {
    field: "updated_by",
    display_name: "更新者"
  },
  {
    field: "deleted_by",
    display_name: "削除者"
  },
  {
    field: "created_at",
    display_name: "作成日時"
  },
  {
    field: "updated_at",
    display_name: "更新日時"
  },
  {
    field: "deleted_at",
    display_name: "削除日時"
  },
  {
    field: "subject",
    display_name: "件名"
  },
  {
    field: "content",
    display_name: "内容"
  },
  {
    field: "product_type",
    display_name: "商品/サービス種別"
  },
  {
    field: "product_code",
    display_name: "商品コード"
  },
  {
    field: "product_number",
    display_name: "品番"
  },
  {
    field: "product_name",
    display_name: "商品名"
  },
  {
    field: "product_name_kana",
    display_name: "商品名(カナ)"
  },
  {
    field: "unified_classification_l",
    display_name: "統一分類(大分類)"
  },
  {
    field: "unified_classification_m",
    display_name: "統一分類(中分類)"
  },
  {
    field: "unified_classification_s",
    display_name: "統一分類(小分類)"
  },
  {
    field: "correspondence_content",
    display_name: "対応内容"
  },
  {
    field: "product_lot",
    display_name: "製造ロット"
  },
  {
    field: "memo",
    display_name: "対応メモ"
  },
  {
    field: "defect_request_name",
    display_name: "不具合／要望"
  },
];

const defaultExportSettings = [
  {
    field: "matter_number",
    display_name: "案件番号"
  },
  {
    field: "ex_matter_number",
    display_name: "NO"
  },
  {
    field: "sid_matter_number",
    display_name: "SIDお問い合わせ番号"
  },
  {
    field: "category",
    display_name: "大区分"
  },
  {
    field: "sub_type",
    display_name: "小区分"
  },
  {
    field: "aggregate_type",
    display_name: "集計用"
  },
  {
    field: "inquiry_type",
    display_name: "お問い合わせ方法"
  },
  {
    field: "title",
    display_name: "案件名"
  },
  {
    field: "content",
    display_name: "内容"
  },
  {
    field: "product_code",
    display_name: "商品コード"
  },
  {
    field: "product_number",
    display_name: "品番"
  },
  {
    field: "created_at",
    display_name: "作成日時"
  },
  {
    field: "product_name",
    display_name: "商品名"
  },
  {
    field: "unified_classification_l",
    display_name: "統一分類(大分類)"
  },
  {
    field: "unified_classification_m",
    display_name: "統一分類(中分類)"
  },
  {
    field: "unified_classification_s",
    display_name: "統一分類(小分類)"
  },
  {
    field: "correspondence_content",
    display_name: "対応内容"
  },
  {
    field: "product_lot",
    display_name: "製造ロット"
  },
  {
    field: "defect_request_name",
    display_name: "不具合／要望"
  },
];

const matterLabels = {
  id: 'id',
  matter_number: '案件番号',
  status_flg: '状態',
  category: '大区分',
  subcategory: '中分類',
  sub_type: '小区分',
  aggregate_type: '集計用',
  completion_time: '完了日時',
  inquiry_type: 'お問い合わせ方法',
  return_method: '回答方法',
  title: '案件名',
  nd_display_tel: 'ND表示電話番号',
  last_name: '姓',
  first_name: '名',
  last_name_kana: '姓(よみがな)',
  first_name_kana: '名(よみがな)',
  email1: 'メールアドレス１',
  email2: 'メールアドレス２',
  phone_number1: '電話１',
  phone_number2: '電話２',
  customer_type_flg: 'お客様種別',
  company_name: '会社名',
  company_name_kana: '会社名(よみがな)',
  gender: '性別',
  age_group: '年齢層',
  industry_name: '業種名',
  department_name: '部署名',
  postal_code: '郵便番号',
  prefecture_name: '都道府県',
  address: '住所',
  fax_number: 'FAX',
  retailer_name: '購入先',
  remarks: '備考',
  product_id: '商品ID',
  created_by: '作成者',
  updated_by: '更新者',
  deleted_by: '削除者',
  created_at: '作成日時',
  updated_at: '更新日時',
  deleted_at: '削除日時',
  subject: '件名',
  content: '内容',
  product_type: '商品/サービス種別',
  product_code: '商品コード',
  product_number: '品番',
  product_name: '商品名',
  product_name_kana: '商品名(カナ)',
  unified_classification_l: '統一分類(大分類)',
  unified_classification_m: '統一分類(中分類)',
  unified_classification_s: '統一分類(小分類)',
  correspondence_content: '対応内容',
  product_lot: '製造ロット',
  memo: '対応メモ',
  defect_request_name: '不具合／要望'
};

const defaultMatterFields = [
  'matter_number', 'category', 'sub_type','aggregate_type',"inquiry_type","title",
  "content","product_code",'product_name',"product_number","unified_classification_l",
  "unified_classification_m","unified_classification_s","correspondence_content",
  "product_lot",'created_at'
];

const defaultMatterFileHeaders = [
  '案件番号','中分類','小区分','集計用','お問い合わせ方法','案件名','内容','商品コード','商品名','品番',
  '統一分類(大分類)','統一分類(中分類)','統一分類(小分類)','対応内容','製造ロット','作成日時'
];
//

const guestMatterFields = [
  'received_time','category','sub_type','aggregate_type','product_name','title','content','gender','defect_request_name'
];

const guestMatterFileHeaders = [
  '問い合わせ日時','大区分','小区分','集計用','商品名','タイトル','内容','性別','不具合／要望'
];
//

//todo set default header in mater table
const defaultMatterHeaders = [
  {
    text: '方法',
    align: 'start',
    width: 100,
    value: 'inquiryTypeName',
  },
  {
    text: '問い合わせ日時',
    width: 150,
    value: 'inquiryDate'
  },
  {
    text: '大区分',
    width: 170,
    value: 'category',
  },
  {
    text: '小区分',
    width: 170,
    value: 'sub_type'
  },
  {
    text: '集計用',
    width: 170,
    value: 'aggregate_type'
  },
  {
    text: '小分類',
    width: 150,
    value: 'unified_classification_s'
  },
  {
    text: '商品名',
    width: 170,
    value: 'product_name'
  },
  {
    text: 'タイトル',
    width: 100,
    value: 'matterTitle'
  },
  {
    text: '内容',
    width: 170,
    value: 'content'
  },
];

const allMatterfieldSettings = [
  {
    field: 'inquiryTypeName',
    display_field_name: '方法',
  },
  {
    field: 'inquiryDate',
    display_field_name: '問い合わせ日時',
  },
  {
    field: 'category',
    display_field_name: '大区分',
  },
  {
    field: 'sub_type',
    display_field_name: '小区分',
  },
  {
    field: 'aggregate_type',
    display_field_name: '集計用',
  },
  {
    field: 'unified_classification_l',
    display_field_name: '大分類',
  },
  {
    field: 'unified_classification_m',
    display_field_name: '中分類',
  },
  {
    field: 'unified_classification_s',
    display_field_name: '小分類',
  },
  {
    field: 'product_number',
    display_field_name: '品番',
  },
  {
    field: 'product_name',
    display_field_name: '商品名',
  },
  {
    field: 'matterTitle',
    display_field_name: 'タイトル',
  },
  {
    field: 'content',
    display_field_name: '内容',
  },
  {
    field: 'correspondence_content',
    display_field_name: '対処対策',
  },
  {
    field: 'customer_type_flg',
    display_field_name: 'お客様種別',
  },
  {
    field: 'gender',
    display_field_name: '性別',
  },
  {
    field: 'age_group',
    display_field_name: '年齢層',
  },
  {
    field: 'industry_name',
    display_field_name: '業種',
  },
  {
    field: 'prefecture_name',
    display_field_name: '都道府県',
  },
];

const defaultMatterHeaderSettings = [
  {
    field: 'inquiryTypeName',
    display_field_name: '方法',
  },
  {
    field: 'inquiryDate',
    display_field_name: '問い合わせ日時',
  },
  {
    field: 'category',
    display_field_name: '大区分',
  },
  {
    field: 'sub_type',
    display_field_name: '小区分',
  },
  {
    field: 'aggregate_type',
    display_field_name: '集計用',
  },
  {
    field: 'unified_classification_s',
    display_field_name: '小分類',
  },
  {
    field: 'product_name',
    display_field_name: '商品名',
  },
  {
    field: 'matterTitle',
    display_field_name: 'タイトル',
  },
  {
    field: 'content',
    display_field_name: '内容',
  },
];
const guestMatterHeaderSettings = [
  {
    field: 'inquiryDate',
    display_field_name: '問い合わせ日時',
  },
  {
    field: 'category',
    display_field_name: '大区分',
  },
  {
    field: 'sub_type',
    display_field_name: '小区分',
  },
  {
    field: 'aggregate_type',
    display_field_name: '集計用',
  },
  {
    field: 'product_name',
    display_field_name: '商品名',
  },
  {
    field: 'matterTitle',
    display_field_name: 'タイトル',
  },
  {
    field: 'content',
    display_field_name: '内容',
  },
];
const guestMatterHeaders = [
  {
    text: '問い合わせ日時',
    width: 150,
    value: 'inquiryDate'
  },
  {
    text: '大区分',
    width: 170,
    value: 'category',
  },
  {
    text: '小区分',
    width: 170,
    value: 'sub_type'
  },
  {
    text: '集計用',
    width: 170,
    value: 'aggregate_type'
  },
  {
    text: '商品名',
    width: 170,
    value: 'product_name'
  },
  {
    text: 'タイトル',
    width: 100,
    value: 'matterTitle'
  },
  {
    text: '内容',
    width: 170,
    value: 'content'
  },
];

const serverFlag = {
  'webcs': 0,
  'oshop': 1,
  'sid': 2,
  'mo': 3,
  'all': null
}

const serverLaBel = {
  'webcs': 'お客様相談室',
  'oshop': 'オフィシャルショップ',
  'sid': 'Shachihata ID',
  'mo': 'メールオーダー',
  'all': '全て'
}
const dashboardLabel = {
  'notSetLabel': '未設定',
}
//
export default {
  inquiryTypeList: inquiryTypeList,
  defaultMatterHeaders: defaultMatterHeaders,
  allMatterfieldSettings: allMatterfieldSettings,
  defaultMatterHeaderSettings: defaultMatterHeaderSettings,
  guestMatterHeaders: guestMatterHeaders,
  guestMatterHeaderSettings: guestMatterHeaderSettings,

  //todo settings export CSV
  matterLabels: matterLabels,
  defaultMatterFields: defaultMatterFields,
  defaultMatterFileHeaders: defaultMatterFileHeaders,
  guestMatterFields: guestMatterFields,
  guestMatterFileHeaders: guestMatterFileHeaders,
  allExportSettings: allExportSettings,
  defaultExportSettings: defaultExportSettings,
  serverFlag: serverFlag,
  serverLaBel: serverLaBel,
  dashboardLabel: dashboardLabel,
}
