<template>
  <div>
    <v-card class="ma-2">
      <v-card-text ma-0 pa-0 style='padding: 0;'>
        <v-container fluid>
          <v-row>
            <v-col cols="9" style='padding-bottom: 0;'>
              <!-- DB選択の3つのボタン -->
              <v-btn
                :color="CUSTOMER_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2 pl-2" style="width: 120px" @click="dbSelect(CUSTOMER_DB);">お客様マスタ
              </v-btn>
              <v-btn
                :color="PRODUCT_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 120px" @click="dbSelect(PRODUCT_DB);">商品マスタ
              </v-btn>
              <v-btn
                :color="USER_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 120px" @click="dbSelect(USER_DB);">ユーザマスタ
              </v-btn>
              <v-btn
                :color="SUBTYPE_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 120px" @click="dbSelect(SUBTYPE_DB);">小区分
              </v-btn>
              <v-btn
                :color="CLASSIFICATION_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 120px" @click="dbSelect(CLASSIFICATION_DB);">分類マスタ
              </v-btn>
              <v-btn
                :color="MASTER_ASSIGNABLE_USER_ORDER_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 120px" @click="dbSelect(MASTER_ASSIGNABLE_USER_ORDER_DB);">担当者の並び順
              </v-btn>
              <v-btn
                :color="SURVEYS_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 130px" @click="dbSelect(SURVEYS_DB);">アンケートマスタ
              </v-btn>
              <v-btn
                :color="AGGREGATE_TYPE_DB == select_db ? 'primary' : 'blue lighten-4'"
                class="ma-2" style="width: 130px" @click="dbSelect(AGGREGATE_TYPE_DB);">集計用
              </v-btn>
            </v-col>
            <v-col cols="3" style='margin-bottom: 0;padding-bottom: 0;' v-if="!(SUBTYPE_DB == select_db) && !(MASTER_ASSIGNABLE_USER_ORDER_DB == select_db) && !(AGGREGATE_TYPE_DB == select_db)">
              <p class="text-right" style='bottom: 0; margin-top: 20px;padding-bottom: 0;font-size: 10px'>
                ダブルクリックで詳細表示</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <MasterSubtype v-if="SUBTYPE_DB == select_db"></MasterSubtype>
    <MasterAggregateTypes v-if="AGGREGATE_TYPE_DB == select_db"></MasterAggregateTypes>
    <MasterAssignableUserOrder v-if="MASTER_ASSIGNABLE_USER_ORDER_DB == select_db"></MasterAssignableUserOrder>
    <v-card class="ma-2 pa-0 customer-table-db" v-if="!(SUBTYPE_DB == select_db) && !(MASTER_ASSIGNABLE_USER_ORDER_DB == select_db) && !(AGGREGATE_TYPE_DB == select_db)">
      <v-data-table dense
                    :headers="headers"
                    :calculate-widths="true"
                    :items="items"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    class="elevation-1 ma-2"
                    :hide-default-footer=true
                    :items-per-page="itemsPerPage"
                    no-data-text='検索条件に一致するデータがありません'
                    locale="ja"
                    @dblclick:row="showItem"
                    :loading="loading"
                    loading-text="読み込み中。。。待って下さい"
      >
        <template v-slot:[`item.remarks`]="{ item }">
          <tooltip-item-table :tooltip-text="item.remarks"/>
        </template>
        <template v-slot:[`item.productName`]="{ item }">
          <tooltip-item-table :tooltip-text="item.productName"/>
        </template>
        <template v-slot:top>
          <v-toolbar dense flat v-if="!(SUBTYPE_DB == select_db) && !(MASTER_ASSIGNABLE_USER_ORDER_DB == select_db) && !(AGGREGATE_TYPE_DB == select_db)">
            <v-row>
              <v-col cols="3" class="d-flex box-customer-limit">
                <div>
                  <p class="mt-3 font-size-limit"> 表示件数:</p>
                </div>
                <div style="width: 30%">
                  <v-select
                    class="square ml-2 customer-items-per-page font-size-limit"
                    :value="itemsPerPage"
                    :items="listRowLimit"
                    label=""
                    solo
                    flat
                    outlined
                    dense
                    hide-details
                    @change="changeItemsPerPage($event)"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
      <div class="text-center py-1" v-show="items.length > 0">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
      </div>
    </v-card>
    <v-card class="ma-2 pt-5" v-if="!(SUBTYPE_DB == select_db) && !(MASTER_ASSIGNABLE_USER_ORDER_DB == select_db) && !(AGGREGATE_TYPE_DB == select_db)">
      <v-container fluid>
        <v-row>
          <v-col>
            <!-- 各マスタのモーダルのコンポーネント -->
            <masterCustomerDialog :dialog-title="dialogTitle" :read-only="readOnly"
                                  :customer-mst-dialog="customerMstDialog" :action-mode="this.actMode"
                                  :dialog-items="dialogItems" @clear="clearEvent" @result="response"
                                  @reload="reloadData"></masterCustomerDialog>
            <masterProductionDialog :dialog-title="dialogTitle" :read-only="readOnly"
                                    :production-mst-dialog="productionMstDialog" :action-mode="this.actMode"
                                    :dialog-items="dialogItems" @clear="clearEvent" @result="response"
                                    @reload="reloadData"></masterProductionDialog>
            <masterUserDialog :dialog-title="dialogTitle" :read-only="readOnly" :usr-mst-dialog="usrMstDialog"
                              :action-mode="this.actMode" :dialog-items="dialogItems" :saved-settings="current_user_settings" @clear="clearEvent"
                              @result="response" @reload="reloadData" @closeSetting="closeUserSetting"
                              @settingChange="changeCurrentSetting"
                              @saveUserSetting="saveUserSetting"
            ></masterUserDialog>
            <MasterProductClassificationDialog :dialog-title="dialogTitle" :read-only="readOnly" :classificationMstDialog="classificationMstDialog"
                              :action-mode="this.actMode" :dialog-items="dialogItems" @clear="clearEvent"
                              @result="response" @reload="reloadData" ></MasterProductClassificationDialog>
            <MasterQuestionSurveyDialog :dialog-title="dialogTitle" :read-only="readOnly" :surveyMstDialog="surveyMstDialog"
                                               :action-mode="this.actMode" :dialog-items="dialogItems" @clear="clearEvent" :questionsList="surveyQuestions"
                                               @result="response" @reload="reloadData" ></MasterQuestionSurveyDialog>
            <!-- 検索 -->
            <v-btn color="primary ml-1 mt-2" style="width: 120px" @click.stop="searchData">検索</v-btn>
            <!-- 登録 -->
            <v-btn color="primary ml-1 mt-2" style="width: 120px" @click.stop="registData" v-if="SURVEYS_DB !== select_db && ($store.state.auth.user.isAdmin || ($store.state.auth.user.isUser && select_db == SUBTYPE_DB && AGGREGATE_TYPE_DB == select_db))">新規</v-btn>
            <download-csv ref="export" :data="dataExport" :labels="customerLabel" :fields="customerField"
                          encoding="utf-8" name="customer_data.csv" style="display: none"></download-csv>
            <v-btn v-if="(select_db != CLASSIFICATION_DB) && (SURVEYS_DB !== select_db) " absolute right color="primary mt-2" style="width: 120px; margin-right: 270px;" @click="exportCsv">
              ＣＳＶ出力
            </v-btn>
            <v-btn absolute right color="primary mt-2" style="width: 120px; margin-right: 135px;" v-if="select_db != CLASSIFICATION_DB"
                   @click="importDialog = true"
            >
              ＣＳＶ取り込み
            </v-btn>

            <v-btn absolute right color="primary mt-2" style="width: 100px;" @click="backToPage">戻る</v-btn>
            <v-dialog
              v-model="importDialog"
              persistent
              max-width="600px"
            >

              <v-card>

                <v-card-title>
                  <span class="text-h5" v-if="0 == select_db">{{ '顧客情報のCSV,ZIP取り込み' }}</span>
                  <span class="text-h5" v-if="1 == select_db">{{ '商品情報のCSV,ZIP取り込み' }}</span>
                  <span class="text-h5" v-if="SURVEYS_DB == select_db">{{ '調査一覧CSV,ZIP取り込み' }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <input accept=".csv, .zip" type="file" ref="fileUpload" @change="importFileChange"
                               style="display: none">
                        <v-btn color="blue-grey" class="ma-2 white--text" @click="$refs.fileUpload.click()">
                          アップロード
                          <v-icon right dark> mdi-cloud-upload</v-icon>
                        </v-btn>
                        <span v-if="validFile" :disabled="!validFile">
                          {{ fileName }}
                        </span>
                        <div v-if="fileImportError.flag">
                          <p style="color: red; text-align: left">{{ fileImportError.message }}</p>
                        </div>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="resetFileImport"
                  >
                    閉じる
                  </v-btn>
                  <v-btn :disabled="!validFile"
                         color="blue darken-1"
                         text
                         @click="importCSV"
                  >
                    インポート
                  </v-btn>
                </v-card-actions>
              </v-card>


            </v-dialog>
          </v-col>
          <v-row justify="center">
            <v-dialog
              v-model="importDialogLoading"
              persistent
              max-width="290"
            >
              <v-card class="text-center">
                <v-card-title class="text-h5" style="display: inherit">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                    class="text-center"
                  ></v-progress-circular>
                </v-card-title>
                <v-card-text style="font-size: 18px">Loading...</v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </v-row>
      </v-container>
      <v-dialog v-model="notificationImportCSVProduct" scrollable max-width="550px">
        <v-card>
          <v-card-title class="text-h5" style="margin: auto">csv取り込み中です。<br>取り込み状況メニューからご確認ください。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" large class="px-15" @click="notificationImportCSVProduct = false">閉じる</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import constants from '@/components/helpers/const';
import masterUserDialog from "@/components/parts/MasterUserDialog.vue"
import masterProductionDialog from "@/components/parts/MasterProductionDialog.vue"
import masterCustomerDialog from "@/components/parts/MasterCustomerDialog.vue"
import userService from '@/services/userService.js';
import productService from '@/services/productService.js';
import questionsSurveyCsvService from '@/services/questionsSurveyCsvService.js';
import customerService from '@/services/customerService.js';
import MasterSubtype from "@/components/parts/MasterSubtype";
import MasterAggregateTypes from "@/components/parts/MasterAggregateTypes";
import MasterAssignableUserOrder from "@/components/parts/MasterAssignableUserOrder";
import MasterProductClassificationDialog from "@/components/parts/MasterProductClassificationDialog";
import MasterQuestionSurveyDialog from "@/components/parts/MasterQuestionSurveyDialog";
import productClassificationService from "../services/productClassificationService";
import userSettingService from "@/services/userSettingService";
import TooltipItemTable from '@/components/TooltipItemTable.vue';

/* 各ヘッダのオリジナルバックアップ
      customer_headers: [
        { text: 'id', value: 'id', align: 'center', class: 'px-0' },
        { text: '姓', value: 'last_name', align: 'center', class: 'px-0' },
        { text: '名', value: 'first_name', align: 'center', class: 'px-0' },
        { text: '姓(よみがな)', value: 'last_name_kana', align: 'center', class: 'px-0' },
        { text: '名(よみがな)', value: 'first_name_kana', align: 'center', class: 'px-0' },
        { text: 'メールアドレス１', value: 'email1', align: 'center', class: 'px-0' },
        { text: 'メールアドレス２', value: 'email2', align: 'center', class: 'px-0' },
        { text: '電話１', value: 'phone_number1', align: 'center', class: 'px-0' },
        { text: '電話２', value: 'phone_number2', align: 'center', class: 'px-0' },
        { text: 'お客様種別', value: 'customer_type_flg', align: 'center', class: 'px-0' },
        { text: '性別', value: 'gender', align: 'center', class: 'px-0' },
        { text: '業種名', value: 'industry_name', align: 'center', class: 'px-0' },
        { text: '部署名', value: 'department_name', align: 'center', class: 'px-0' },
        { text: '郵便番号', value: 'postal_code', align: 'center', class: 'px-0' },
        { text: '都道府県', value: 'prefecture_name', align: 'center', class: 'px-0' },
        { text: '住所', value: 'address', align: 'center', class: 'px-0', width: '200px' },
        { text: 'FAX', value: 'fax_number', align: 'center', class: 'px-0' },
        { text: '備考', value: 'remarks', align: 'center', class: 'px-0' },
        { text: '作成者', value: 'created_by', align: 'center', class: 'px-0' },
        { text: '更新者', value: 'updated_by', align: 'center', class: 'px-0' },
        { text: '削除者', value: 'deleted_by', align: 'center', class: 'px-0' },
        { text: '作成日時', value: 'created_at', align: 'center', class: 'px-0' },
        { text: '更新日時', value: 'updated_at', align: 'center', class: 'px-0' },
        { text: '削除日時', value: 'deleted_at', align: 'center', class: 'px-0' }
      ],
      product_headers: [
        { text: 'id', value: 'id', align: 'center', },
        { text: '商品/サービス種別', value: 'product_type' , align: 'center', class: 'px-0'},
        { text: '商品コード', value: 'product_code', align: 'center', class: 'px-0' },
        { text: '品番', value: 'product_number', align: 'center', class: 'px-0' },
        { text: '商品名', value: 'product_name', align: 'center', class: 'px-0' },
        { text: '商品名(カナ)', value: 'product_name_kana', align: 'center', class: 'px-0' },
        { text: '大分類', value: 'unified_classification_l', align: 'center', class: 'px-0' },
        { text: '中分類', value: 'unified_classification_m', align: 'center', class: 'px-0' },
        { text: '小分類', value: 'unified_classification_s', align: 'center', class: 'px-0' },
        { text: 'バーコード', value: 'barcode', align: 'center', class: 'px-0' },
        { text: '小売価格', value: 'retail_price', align: 'center', class: 'px-0' },
        { text: '色名', value: 'color_name', align: 'center', class: 'px-0' },
        { text: '幅', value: 'size_width', align: 'center', class: 'px-0' },
        { text: '奥行き', value: 'size_depth', align: 'center', class: 'px-0' },
        { text: '高さ', value: 'size_height', align: 'center', class: 'px-0' },
        { text: '重量', value: 'size_weight', align: 'center', class: 'px-0' },
        { text: '中箱１入数', value: 'middle_box_quantity', align: 'center', class: 'px-0' },
        { text: '梱箱入数', value: 'packing_box_quantity', align: 'center', class: 'px-0' },
        { text: 'オープン価格', value: 'open_price_type', align: 'center', class: 'px-0' },
        { text: '参考価格A', value: 'reference_price_A', align: 'center', class: 'px-0' },
        { text: '代替品番', value: 'alternative_product_number', align: 'center', class: 'px-0' },
        { text: '発売年月日', value: 'release_date', align: 'center', class: 'px-0' },
        { text: 'カタログ掲載終了', value: 'catalog_end_date', align: 'center', class: 'px-0' },
        { text: '環境対応フラグ', value: 'env_support_flg', align: 'center', class: 'px-0' },
        { text: 'エコマーク', value: 'eco_mark_flg', align: 'center', class: 'px-0' },
        { text: '再生樹脂比率', value: 'eco_mark_rate', align: 'center', class: 'px-0' },
        { text: 'グリーン購入法', value: 'green_purchase_law', align: 'center', class: 'px-0' },
        { text: '作成者', value: 'created_by', align: 'center', class: 'px-0' },
        { text: '更新者', value: 'updated_by', align: 'center', class: 'px-0' },
        { text: '削除者', value: 'deleted_by', align: 'center', class: 'px-0' },
        { text: '作成日時', value: 'created_at', align: 'center', class: 'px-0' },
        { text: '更新日時', value: 'updated_at', align: 'center', class: 'px-0' },
        { text: '削除日時', value: 'deleted_at', align: 'center', class: 'px-0' }
      ],
      usr_headers: [
        { text: 'id', value: 'id', align: 'center', class: 'px-0' },
        { text: 'ログインID', value: 'login_id', align: 'center', class: 'px-0' },
        { text: 'パスワード', value: 'password', align: 'center', class: 'px-0' },
        { text: 'メールアドレス', value: 'email', align: 'center', class: 'px-0' },
        { text: '姓', value: 'last_name', align: 'center', class: 'px-0' },
        { text: '名', value: 'first_name', align: 'center', class: 'px-0' },
        { text: '権限（管理者or利用者）', value: 'auth_flg', align: 'center', class: 'px-0' },
        { text: '部署名', value: 'department_name', align: 'center', class: 'px-0' },
        { text: '作成者', value: 'created_by', align: 'center', class: 'px-0' },
        { text: '更新者', value: 'updated_by', align: 'center', class: 'px-0' },
        { text: '削除者', value: 'deleted_by', align: 'center', class: 'px-0' },
        { date: '作成日時', value: 'created_at', align: 'center', class: 'px-0' },
        { text: '更新日時', value: 'updated_at', align: 'center', class: 'px-0' },
        { text: '削除日時', value: 'deleted_at', align: 'center', class: 'px-0' }
      ],
*/
const ACT_MODE_SEARCH = 0
const ACT_MODE_REGIST = 1
const ACT_MODE_SHOWITEM = 2

export default {
  name: 'MasterDataManage',
  data: function () {
    return {
      CUSTOMER_DB: 0,
      PRODUCT_DB: 1,
      USER_DB: 2,
      SUBTYPE_DB: 3,
      CLASSIFICATION_DB: 4,
      MASTER_ASSIGNABLE_USER_ORDER_DB: 5,
      SURVEYS_DB: 6,
      AGGREGATE_TYPE_DB: 7,
      readOnly: true,
      loading: false,
      actMode: 0,
      dialogTitle: '',
      dialogItems: {},
      aa: "xx",
      bb: 0,
      customerMstDialog: false,
      productionMstDialog: false,
      usrMstDialog: false,
      classificationMstDialog: false,
      oldCustomerSearchData: {},
      oldProductSearchData: {},
      oldUsrSearchData: {},
      oldClassificationSearchData:{},
      oldCustomerRegistData: {},
      oldProductRegistData: {},
      oldUsrRegisthData: {},
      surveyMstDialog: false,
      oldSurveySearchData: {},
      surveyQuestions : [],
      message: {},
//      message: '',

      // Import csv file customer data
      file: null,
      validFile: false,
      fileImportError: {
        message: '',
        flag: false,
      },
      fileName: "",
      importDialog: true,
      importDialogLoading: false,

      empty_array: [],
      dialog: false,
      notificationImportCSVProduct: false,
      select_db: -1,
      customerTypeFlgName: ['個人消費者', '企業消費者', '販売店', '支店', 'その他'],
      attentionFlgName: ['注意', '要注意'],
      genderName: ['男', '女', 'その他'],
      ageGroupName: ['若年層', '中年層', '高齢層'],
      authFlgName: ['管理者', '利用者', '閲覧者','ゲスト'],
      customer_headers: [
        {text: 'id', value: 'id', align: 'center', class: 'pa-0'},
        {text: '名前', value: 'fullName', align: 'center', class: 'pa-0'},
        {text: '名前（かな）', value: 'fullNameKana', align: 'center', class: 'pa-0'},
        {text: 'メールアドレス１', value: 'email1', align: 'center', class: 'pa-0'},
        /*         { text: 'メールアドレス２', value: 'email2', align: 'center', class: 'pa-0' }, */
        {text: '電話１', value: 'phoneNumber1', align: 'center', class: 'pa-0'},
        /*         { text: '電話２', value: 'phone_number2', align: 'center', class: 'pa-0' }, */
        {text: '種別', value: 'customerTypeFlg', align: 'center', class: 'pa-0'},
        {text: '会社名', value: 'companyName', align: 'center', class: 'pa-0'},
        {text: '会社名（かな）', value: 'companyNameKana', align: 'center', class: 'pa-0'},
        {text: '性別', value: 'gender', align: 'center', class: 'pa-0'},
        {text: '年齢層', value: 'age_group', align: 'center', class: 'pa-0'},
        {text: '業種名', value: 'industryName', align: 'start', class: 'pa-0'},
        /*         { text: '部署名', value: 'department_name', align: 'center', class: 'pa-0' }, */
        {text: '郵便番号', value: 'postalCode', align: 'center', class: 'pa-0'},
        {text: '都道府県', value: 'prefectureName', align: 'center', class: 'pa-0'},
        {text: '住所', value: 'address', align: 'center', class: 'pa-0'},
        {text: 'FAX', value: 'faxNumber', align: 'center', class: 'pa-0'},
        {text: '備考', value: 'remarks', align: 'center', class: 'pa-0'}
      ],
      product_headers: [
        {text: 'id', value: 'id', align: 'center', class: 'pa-0'},
        {text: '商品/サービス種別', value: 'productType', align: 'center', class: 'px-0'},
        {text: '商品コード', value: 'productCode', align: 'center', class: 'px-0'},
        {text: '品番', value: 'productNumber', align: 'center', class: 'px-0'},
        {text: '商品名', value: 'productName', align: 'center', class: 'px-0'},
        /*         { text: 'カナ', value: 'product_name_kana', align: 'center', class: 'px-0' }, */
        {text: '大分類', value: 'unifiedClassificationL', align: 'center', class: 'px-0'},
        {text: '中分類', value: 'unifiedClassificationM', align: 'center', class: 'px-0'},
        {text: '小分類', value: 'unifiedClassificationS', align: 'center', class: 'px-0'},
        /*         { text: 'バーコード', value: 'barcode', align: 'center', class: 'px-0' }, */
        {text: '小売価格', value: 'retailPrice', align: 'center', class: 'px-0'},
        {text: '色名', value: 'colorName', align: 'center', class: 'px-0'},
        {text: '幅', value: 'sizeWidth', align: 'center', class: 'px-0'},
        {text: '奥行き', value: 'sizeDepth', align: 'center', class: 'px-0'},
        {text: '高さ', value: 'sizeHeight', align: 'center', class: 'px-0'},
        {text: '重量', value: 'sizeWeight', align: 'center', class: 'px-0'},
        /*         { text: '中箱１入数', value: 'middle_box_quantity', align: 'center', class: 'px-0' },
                { text: '梱箱入数', value: 'packing_box_quantity', align: 'center', class: 'px-0' }, */
        {text: 'オープン価格', value: 'openPriceType', align: 'center', class: 'px-0'},
        /*         { text: '参考価格A', value: 'reference_price_A', align: 'center', class: 'px-0' }, */
        {text: '代替品番', value: 'alternativeProductNumber', align: 'center', class: 'px-0'},
        {text: '発売年月日', value: 'releaseDate', align: 'center', class: 'px-0'},
        {text: '掲載終了', value: 'catalogEndDate', align: 'center', class: 'px-0'}/* ,
        { text: '環境対応フラグ', value: 'env_support_flg', align: 'center', class: 'px-0' },
        { text: 'エコマーク', value: 'eco_mark_flg', align: 'center', class: 'px-0' },
        { text: '再生樹脂比率', value: 'eco_mark_rate', align: 'center', class: 'px-0' },
        { text: 'グリーン購入法', value: 'green_purchase_law', align: 'center', class: 'px-0' } */
      ],
      usr_headers: [
        {text: 'id', value: 'id', align: 'center', class: 'pa-0'},
        {text: 'ログインID', value: 'loginId', align: 'center', class: 'px-0'},
        {text: 'メールアドレス', value: 'email', align: 'center', class: 'px-0'},
        {text: '名前', value: 'fullName', align: 'center', class: 'px-0'},
        {text: '権限', value: 'authFlg', align: 'center', class: 'px-0'},
        {text: '部署名', value: 'departmentName', align: 'center', class: 'px-0'}
      ],
      classification_hearders:[
        {text: 'ID', value: 'id', align: 'center', class: 'pa-0'},
        {text: '分類コード', value: 'classification_code', align: 'center', class: 'pa-0'},
        {text: '分類名', value: 'classification_name', align: 'center', class: 'pa-0'},
        // {text: 'Type', value: 'type', align: 'center', class: 'pa-0'},
        // {text: 'Classification_M', value: 'classification_M', align: 'center', class: 'pa-0'},
      ],
      sortBy: 'id',
      sortDesc: [true],
      page: 1,
      pageCount: null,
      headers: [],
      items: [],
      customerField: ['id', 'lastName', 'lastNameKana', 'firstNameKana', 'email1', 'email2', 'phoneNumber1', 'phoneNumber2', 'customerTypeFlg', 'attentionFlg', 'companyName', 'companyNameKana', 'gender', 'age_group', 'industryName', 'departmentName', 'postalCode', 'prefectureName', 'address', 'faxNumber', 'retailerName', 'remarks', 'old_customer_id', 'status', 'created_user', 'created_time', 'classification','mobile_number1', 'mobile_number2','address_prefecture', 'importance_flg','memo', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'],
      productField: ['id', 'productType', 'productCode', 'productNumber', 'productName', 'productNameKana', 'unifiedClassificationL', 'unifiedClassificationM', 'unifiedClassificationS', 'barcode', 'retailPrice', 'colorName', 'sizeWidth', 'sizeDepth', 'sizeHeight', 'sizeWeight', 'middleBoxQuantity', 'packingBoxQuantity', 'openPriceType', 'referencePriceA', 'alternativeProductNumber', 'releaseDate', 'catalogEndDate', 'envSupportFlg', 'ecoMarkFlg', 'ecoMarkRate', 'greenPurchaseLaw', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'],
      userField: ['id', 'loginId', 'email', 'firstName', 'lastName', 'authFlg', 'departmentName', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'],
      dataExport: [],
      customerLabel: {
        id: 'id',
        lastName: '名前',
        lastNameKana: '名前（かな）',
        firstNameKana: '名（よみがな）',
        email1: 'E-Mail①',
        email2: 'E-Mail②',
        phoneNumber1: 'Tel①',
        phoneNumber2: 'Tel②',
        customerTypeFlg: 'お客様種別',
        attentionFlg: '要注意フラグ',
        companyName: '会社名',
        companyNameKana: '会社名（かな）',
        gender: '性別',
        age_group: '年齢層',
        industryName: '業種名',
        departmentName: '部署名',
        postalCode: '郵便番号',
        prefectureName: '都道府県',
        address: '住所',
        faxNumber: 'FAX',
        retailerName: '購入先',
        remarks: '備考',
        old_customer_id:  'お客様ID',
        status: 'ｽﾃｰﾀｽ',
        created_user: '新規.USER',
        created_time: '新規.TIME',
        classification: '区分',
        mobile_number1: '携帯①',
        mobile_number2: '携帯②',
        address_prefecture: '住所（都道府県）',
        importance_flg: '重要度',
        memo: '特記事項',
        createdAt: '登録日',
        updatedAt: '更新日',
        createdBy: '登録者',
        updatedBy: '更新者'
      },
      productLabel: {
        id: 'id',
        productType: '商品/サービス種別',
        productCode: '商品コード',
        productNumber: '品番',
        productName: '商品名',
        productNameKana: '商品名(カナ)',
        unifiedClassificationL: '統一分類(大分類)',
        unifiedClassificationM: '統一分類(中分類)',
        unifiedClassificationS: '統一分類(小分類)',
        barcode: '単品バーコード',
        retailPrice: '小売価格',
        colorName: '色名',
        sizeWidth: '製品サイズ:幅',
        sizeDepth: '製品サイズ:奥行き',
        sizeHeight: '製品サイズ:高さ',
        sizeWeight: '製品サイズ:重量',
        middleBoxQuantity: '中箱１入数',
        packingBoxQuantity: '梱箱入数(読み方はコンバコ？)',
        openPriceType: 'オープン価格区分',
        referencePriceA: '参考価格A',
        alternativeProductNumber: '代替品番',
        releaseDate: '発売年月日',
        catalogEndDate: 'カタログ掲載終了',
        envSupportFlg: '環境対応フラグ',
        ecoMarkFlg: 'エコマークフラグ',
        ecoMarkRate: 'エコマーク再生樹脂比率',
        greenPurchaseLaw: 'グリーン購入法',
        createdAt: '作成日',
        updatedAt: '更新日',
        createdBy: '作成者',
        updatedBy: '更新者'
      },
      userLabel: {
        id: 'id',
        loginId: 'ログインID',
        email: 'E-Mail',
        lastName: '姓',
        firstName: '名',
        authFlg: '権限',
        departmentName: '部署名',
        createdAt: '作成日',
        updatedAt: '更新日',
        createdBy: '作成者',
        updatedBy: '更新者'
      },

      //for user setting export matters csv
      current_user_settings: [],
      saved_user_settings: [],
      // テーブルに表示する行数
      listRowLimit: [10, 20, 50, 100],
      itemsPerPage: 10,
      // Tabs CUSTOMER_DB, PRODUCT_DB, USER_DB, CLASSIFICATION_DB, SURVEYS_DB
      cookieKeysPerPage: {
        0: { page: "master_customer_list_page", perPage: "master_customer_list_per_page" },
        1: { page: "master_product_list_page", perPage: "master_product_list_per_page" },
        2: { page: "master_user_list_page", perPage: "master_user_list_per_page" },
        4: { page: "master_classification_list_page", perPage: "master_classification_list_per_page" },
        6: { page: "master_surveys_list_page", perPage: "master_surveys_list_per_page" }
      },
    }
  },
  created() {
    this.headers = this.customer_headers
    this.dbSelect(this.CUSTOMER_DB)
  },
  mounted() {
    // タイトルを「マスタ管理」に更新
    this.$store.dispatch('headerTitleChangeAction', 3);
    if (this.select_db in this.cookieKeysPerPage) {
      this.getPageCookie();
    }
  },
  watch: {
    async page() {
      await this.reloadData()
      if (this.select_db in this.cookieKeysPerPage) {
        await this.savePageCookie();
      }
    },
    async sortBy() {
      this.clearData()
      await this.reloadData()
    },
    async sortDesc() {
      this.clearData()
      await this.reloadData()
    },
  },
  methods: {
    response(obj) {
      // 検索したor登録した
      if (obj.res) {
        if (this.customerMstDialog) {
          if (ACT_MODE_SEARCH == this.actMode) {
            this.page = 1
            this.headers = this.customer_headers
            this.oldCustomerSearchData = JSON.parse(JSON.stringify(obj.message))
            this.reloadData();
          }
        } else if (this.productionMstDialog) {
          if (ACT_MODE_SEARCH == this.actMode) {
            this.page = 1
            this.headers = this.product_headers
            this.oldProductSearchData = JSON.parse(JSON.stringify(obj.message))
            this.reloadData();
          }
        } else if (this.usrMstDialog) {
          if (ACT_MODE_SEARCH == this.actMode) {
            this.page = 1
            this.headers = this.usr_headers
            this.oldUsrSearchData = JSON.parse(JSON.stringify(obj.message))
            this.reloadData();
          }
        } else if(this.classificationMstDialog){
            if (ACT_MODE_SEARCH == this.actMode){
              this.page = 1
              this.headers = this.classification_hearders
              this.oldClassificationSearchData = JSON.parse(JSON.stringify(obj.message));
              this.reloadData();
            }
        } else if(this.surveyMstDialog){
            if (ACT_MODE_SEARCH == this.actMode){
              this.page = 1
              this.oldSurveySearchData = JSON.parse(JSON.stringify(obj.message));
              this.reloadData();
            }
        }
      }
      this.clearEvent()
    },
    clearEvent() {
      this.customerMstDialog = false
      this.productionMstDialog = false
      this.usrMstDialog = false
      this.classificationMstDialog = false
      this.surveyMstDialog = false
      this.actMode = -1
    },

    // DB選択
    async dbSelect(dbno) {
      if (this.select_db == dbno || this.loading) {
        return
      } else {
        this.select_db = dbno
        this.clearData()
        this.sortBy = 'id'
        this.sortDesc = [true]
        if (this.select_db in this.cookieKeysPerPage) {
          await this.getPageCookie();
        }
      }
    },
    searchData() {
      this.readOnly = false
      this.actMode = ACT_MODE_SEARCH
      if (this.CUSTOMER_DB == this.select_db) {
        this.dialogTitle = 'お客様マスタ検索'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldCustomerSearchData))
        this.customerMstDialog = true
      } else if (this.PRODUCT_DB == this.select_db) {
        this.dialogTitle = '商品マスタ検索'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldProductSearchData))
        this.productionMstDialog = true
      } else if (this.USER_DB == this.select_db) {
        this.dialogTitle = 'ユーザマスタ検索'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldUsrSearchData))
        this.usrMstDialog = true
      }else if (this.CLASSIFICATION_DB == this.select_db){
        this.dialogTitle = '分類情報検索'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldClassificationSearchData))
        this.classificationMstDialog = true
      }else if (this.SURVEYS_DB == this.select_db){
        this.dialogTitle = 'アンケート結果情報検索'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldSurveySearchData))
        this.surveyMstDialog = true
      }
    },

    // import File
    importFileChange(event) {
      let file = event.target.files[0];
      if (file) {
        let fileNameExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
        // Change the limit from 9M to 4M in issue SHACHIHATADESK-237
        // because the server currently only supports uploading files under 4.5M
        if (file.size > 1024 * 1024 * 4) {
          this.validFile = false;
          this.fileImportError.flag = true;
          this.file = null
          this.fileImportError.message = "CSV,ZIPファイルのサイズが4MBを超えています。"
        } else if (fileNameExtension != 'csv' && fileNameExtension != 'zip') {
          this.validFile = false;
          this.fileImportError.flag = true;
          this.file = null
          this.fileImportError.message = "CSV,ZIPファイルを選択してください。"
        } else {
          this.fileName = file.name;
          this.fileImportError.flag = false;
          this.fileImportError.message = "";
          this.file = file;
          this.validFile = true;
        }
      }
    },
    resetFileImport() {
      this.$refs.fileUpload.value = null
      this.file = null
      this.importDialog = false
      this.validFile = false
      this.fileImportError.message = ''
      this.fileImportError.flag = false
    },
    async importCSV() {
      this.importDialogLoading = true;
      let data = new FormData();
      data.append('file', this.file);
      const headers = {
        'Content-Type': 'multipart/form-data'
      }
      try {
        let res;
        if (this.select_db == 0) {
          res = await customerService.importCustomer(data, headers);
        } else if (this.select_db == 1) {
          res = await productService.importProduct(data, headers);
          if(res.data.status == 200){
            this.notificationImportCSVProduct = true;
          }
        } else if (this.select_db == 6) {
          res = await questionsSurveyCsvService.importSurvey(data, headers);
          if(res.data.status == 200){
            this.notificationImportCSVProduct = true;
          }
        }
        let userImportRes = res.data;
        if (userImportRes.message == "OK") {
          this.importDialogLoading = false;
          this.resetFileImport();
          this.reloadData()
        }
      } catch (error) {
        this.importDialogLoading = false;
        this.validFile = false;
        this.fileImportError.flag = true;
        this.file = null
        let convertErrToString = String(error)
        let checkStatusCode501 = convertErrToString.includes("Request failed with status code 501");
        if (checkStatusCode501 == true){
          this.fileImportError.message = "別のキューが実行されています。完了するまでお待ちください。"
        } else if (this.select_db == 6 && (error.response && error.response.status === 422)){
          this.fileImportError.message = "データが正しくありません"
        } else if (error.response && error.response.status === 413){
          this.fileImportError.message = "CSV,ZIPファイルのサイズが4MBを超えています。"
        } else if (error.response && error.response.status === 504){
          this.fileImportError.message = "リクエストはタイムアウトになりました。インポート処理は失敗しました。"
        } else {
          this.fileImportError.message = "エラーが発生しました。もう一度お試しください。"
        }
      }

    }
    // end of import file
    ,
    registData() {
      this.readOnly = false
      this.actMode = ACT_MODE_REGIST
      if (this.CUSTOMER_DB == this.select_db) {
        this.dialogTitle = 'お客様マスタ登録'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldCustomerRegistData))
        this.customerMstDialog = true
      } else if (this.PRODUCT_DB == this.select_db) {
        this.dialogTitle = '商品マスタ登録'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldProductRegistData))
        this.productionMstDialog = true
      } else if (this.USER_DB == this.select_db) {
        this.dialogTitle = 'ユーザマスタ登録'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldUsrRegisthData))
        this.usrMstDialog = true
      } else if(this.CLASSIFICATION_DB == this.select_db){
        this.dialogTitle = '分類情報登録'
        this.dialogItems = JSON.parse(JSON.stringify(this.oldClassificationSearchData))
        this.classificationMstDialog = true
      }
    },
    async reloadData() {
      this.importDialog = false

      if (this.loading) return

      this.loading = true

      if (this.USER_DB == this.select_db) {
        const res = await this.searchUser(false);
        this.headers = this.usr_headers
        this.items = res.data.data.data
        this.pageCount = res.data.data.last_page
        this.items.forEach(item => {
          item.authFlg = this.authFlgName[item.authFlg]
        })
      } else if (this.PRODUCT_DB == this.select_db) {
        const res = await this.searchProduct(false);
        this.headers = this.product_headers
        this.items = res.data.data.data
        this.items.forEach(item => {
          item.releaseDate = this.formatDate(item.releaseDate)
          item.catalogEndDate = this.formatDate(item.catalogEndDate)
        })
        this.pageCount = res.data.data.last_page
      } else if (this.CUSTOMER_DB == this.select_db) {
        const res = await this.searchCustomer(false);
        this.headers = this.customer_headers
        this.items = res.data.data.data
        this.pageCount = res.data.data.last_page
        this.items.forEach(item => {
          if (item.gender == null){
            item.gender = null
          }else {
            item.gender = this.genderName[item.gender - 1]
          }
          if(item.age_group !== null){
            item.age_group = this.ageGroupName[item.age_group - 1]
          }
          item.customerTypeFlg = this.customerTypeFlgName[item.customerTypeFlg]
        })
      }
      else if(this.CLASSIFICATION_DB == this.select_db){
        const res = await this.searchClassification()
        this.headers = this.classification_hearders
        this.items = res.data.data.data;
        this.pageCount = res.data.data.last_page
      }
      else if(this.SURVEYS_DB == this.select_db){
        if(this.sortBy === 'id'){
          this.sortBy =  'response_id';
        }
        let headerAnswerDateStart = {text: '回答開始日時', value: 'answer_date_start', align: 'center', class: 'px-0'};
        let questions_csv_hearders = [
          {text: '回答者ID', value: 'response_id', align: 'center', class: 'pa-0'},
          {text: '回答日時', value: 'answer_date', align: 'center', class: 'px-0'},
          {text: 'F1_USERID', value: 'f1_user_id', align: 'center', class: 'px-0'},
          headerAnswerDateStart
        ];
        const compareHearder = this.headers.filter(headerOld =>
          questions_csv_hearders.some(hearderNew =>
            headerOld.value === hearderNew.value
          )
        );
        if(compareHearder.length == 0){
          this.headers = questions_csv_hearders;
        }
        try {
          const res = await this.searchSurvey();
          // Remove the last element
          questions_csv_hearders.pop();

          let headerQuestionSurveys = res ? res.data.question_surveys_list : [];
          this.surveyQuestions = headerQuestionSurveys;
          if (headerQuestionSurveys.length > 0) {
            headerQuestionSurveys.forEach((name, index) => {
              questions_csv_hearders.push(
                {text: `Q${index + 1}`, value: `answers_survey_names${name.id}`, sortable: false, align: 'center', class: 'px-0'}
              );
            })
          }
          questions_csv_hearders.push(headerAnswerDateStart);
          this.headers = questions_csv_hearders;

          this.items = res ? res.data.data.data : [];
          this.items.forEach(item => {
            if (item.answers_survey_names.length > 0 || item.questions_survey_id.length > 0) {
              item.answers_survey_names.forEach((name, index) => {
                if (index < item.questions_survey_id.length) {
                  item[`answers_survey_names${item.questions_survey_id[index]}`] = name;
                }
              });
            }
          })

          this.pageCount = res ? res.data.data.last_page : 0;
        } catch (error) {
          this.loading = false;
        }
      }
      if (this.items.length > 0) this.items.forEach(item => {
        item.createdAt = this.formatDate(item.createdAt)
        item.updatedAt = this.formatDate(item.updatedAt)
        item.deletedAt = this.formatDate(item.deletedAt)
      })
      if (this.select_db in this.cookieKeysPerPage && this.items.length == 0) {
        this.page = 1;
      }

      this.loading = false
    },
    async searchCustomer(isExport){
      let data = this.oldCustomerSearchData;
      data.page = this.page
      data.orderBy = !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy;
      data.orderDir = !this.sortDesc[0] ? 'asc' : 'desc';
      data.limit = this.itemsPerPage;
      if (isExport){
        data.export = true;
      }else{
        data.export = '';
      }
      return await customerService.searchForCustomer(data)

    },
    async searchProduct(isExport){
      let data = this.oldProductSearchData;
      data.page = this.page;
      data.orderBy = !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy;
      data.orderDir = !this.sortDesc[0] ? 'asc' : 'desc';
      data.limit = this.itemsPerPage;
      if (isExport){
        data.export = true;
      }else{
        data.export = '';
      }
      return await productService.searchForProduct(data)
    },
    async searchSurvey(){
      let data = this.oldSurveySearchData;
      data.page = this.page;
      data.orderBy = !this.sortBy || this.sortBy.length == 0 ||  this.sortBy == 'id' ? 'response_id' : this.sortBy;
      data.orderDir = !this.sortDesc[0] ? 'asc' : 'desc';
      data.limit = this.itemsPerPage;
      data.export = '';
      return await questionsSurveyCsvService.searchForQuestionsSurveyCsv(data);
    },
    async searchUser(isExport){
      let data = this.oldUsrSearchData;
      if (isExport){
        delete data.page;
      }else{
        data.page =  this.page;
      }
      data.orderBy = !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy;
      data.orderDir = !this.sortDesc[0] ? 'asc' : 'desc';
      data.limit = this.itemsPerPage;
      return await userService.searchForUser(data)
    },
    async searchClassification(){
      let data = this.oldClassificationSearchData;
      data.page = this.page;
      data.orderBy = !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy;
      data.orderDir = !this.sortDesc[0] ? 'asc' : 'desc';
      data.limit = this.itemsPerPage;
      return await productClassificationService.searchClassificationcation(data)
    },
    clearData() {
      this.items = []
    },
    async showItem(event, {item}) {
      this.dialogItems = {}
      this.readOnly = false
      this.actMode = ACT_MODE_SHOWITEM
      if (this.CUSTOMER_DB == this.select_db) {
        this.dialogTitle = 'お客様マスタ表示'
        const res = await customerService.getCustomer(item.id)
        this.dialogItems = res.data.data
        this.customerMstDialog = true
      } else if (this.PRODUCT_DB == this.select_db) {
        this.dialogTitle = '商品マスタ表示'
        const res = await productService.getProduct(item.id)
        this.dialogItems = res.data.data
        this.dialogItems.releaseDate = this.formatDate(this.dialogItems.releaseDate)
        this.dialogItems.catalogEndDate = this.formatDate(this.dialogItems.catalogEndDate)
        this.productionMstDialog = true
      } else if (this.USER_DB == this.select_db) {
        this.dialogTitle = 'ユーザマスタ表示'
        const res = await userService.getUser(item.id)
        this.dialogItems = res.data.data
        this.dialogItems.createdAt = this.dateFormatChange(this.dialogItems.createdAt);
        this.dialogItems.updatedAt = this.dateFormatChange(this.dialogItems.createdAt);
        this.usrMstDialog = true
        //todo user settings export
        await this.getUserSettings(item.id);
        //end user settings
      } else if(this.CLASSIFICATION_DB == this.select_db){
        this.dialogTitle = '分類情報表示'
        const res = await productClassificationService.getClassification(item.id);
        if(res) {
          this.dialogItems = res.data.data
          this.classificationMstDialog = true
        }
      } else if (this.SURVEYS_DB == this.select_db) {
        this.dialogTitle = 'アンケート結果の詳細情報'
        this.dialogItems = item;
        this.surveyMstDialog = true
      }
      this.dialogItems.createdAt = this.formatDate(this.dialogItems.createdAt)
      this.dialogItems.updatedAt = this.formatDate(this.dialogItems.updatedAt)
      this.dialogItems.deletedAt = this.formatDate(this.dialogItems.deletedAt)
    },
    async exportCsv() {
      if (this.loading) return
      if (this.USER_DB == this.select_db) {
        this.$refs.export.labels = this.userLabel
        this.$refs.export.fields = this.userField
        this.$refs.export.name = 'user_data.csv'
        const res = await this.searchUser(true)
        this.dataExport = res.data.data
        this.dataExport.forEach(item => {
          item.authFlg = this.authFlgName[item.authFlg]
        })
      } else if (this.PRODUCT_DB == this.select_db) {
        this.$refs.export.labels = this.productLabel
        this.$refs.export.fields = this.productField
        this.$refs.export.name = 'product_data.csv'
        const res = await this.searchProduct(true)
        this.dataExport = res.data.data
        this.dataExport.forEach(item => {
          item.releaseDate = this.formatDate(item.releaseDate)
          item.catalogEndDate = this.formatDate(item.catalogEndDate)
          item.barcode = item.barcode+'\t';
        })

      } else if (this.CUSTOMER_DB == this.select_db) {
        this.$refs.export.labels = this.customerLabel;
        this.$refs.export.name = 'customer_data.csv'
        const res = await this.searchCustomer(true)
        this.dataExport = res.data.data
        this.dataExport.forEach(item => {
          item.gender = this.genderName[item.gender]
          item.age_group = this.ageGroupName[item.age_group - 1]
          item.attentionFlg = this.attentionFlgName[item.attentionFlg]
          item.customerTypeFlg = this.customerTypeFlgName[item.customerTypeFlg]
        })
      }
      if (this.dataExport.length > 0) this.dataExport.forEach(item => {
        item.createdAt = this.formatDate(item.createdAt)
        item.updatedAt = this.formatDate(item.updatedAt)
        item.deletedAt = this.formatDate(item.deletedAt)
      })
      this.$refs.export.data = this.dataExport
      this.$refs.export.generate()
    },

    //todo user settings
    changeCurrentSetting(arr){
      this.current_user_settings = arr
    },
    closeUserSetting(){
      this.current_user_settings = this.saved_user_settings
    },

    //todo user settings export
    async getUserSettings(id){
      await userSettingService.getUserSettings(id).then(res=>{
        let settings = res.data.data;
        if(!settings || !settings.header_display){
          this.saved_user_settings = constants.defaultExportSettings;
          this.current_user_settings = this.saved_user_settings;
        }
        else {
          this.saved_user_settings = [];
          let setting_fields_display = settings.header_display.split(';').filter(item => item !== '');
          let setting_fields = settings.field_display.split(';').filter(item => item !== '');

          setting_fields_display.forEach((value, index)=>{
            this.saved_user_settings.push(Object.assign({field: setting_fields[index], display_name: value}));
          });
          this.current_user_settings = this.saved_user_settings;
        }
      })
    },

    async saveUserSetting(id){
      await this.getUserSettings(id);
    },

    // change datetime format(from "yyyy-mm-ddT00:00:00.000000Z" → "yyyy/mm/dd")
    dateFormatChange(date) {
      if (date){
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
        const day = ("00" + newDate.getDate()).slice(-2);
        const hours = ("00" + newDate.getHours()).slice(-2);
        const minutes = ("00" + newDate.getMinutes()).slice(-2);
        const seconds = ("00" + newDate.getSeconds()).slice(-2);

        return year + '/' + month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds
      }else{
        return '';
      }
    },
    //

    backToPage() {
      this.$router.go(-1)
    },
    formatDate(date) {
      if (!date) return null
      return date.substring(0, 10).replaceAll('-', '/')
    },
    savePageCookie () {
      const expiresDate = moment().utcOffset(0).add(1, 'days').set({hour:0,minute:0,second:0,millisecond:0});
      expiresDate.toISOString();
      expiresDate.format();
      if (this.cookieKeysPerPage[this.select_db]) {
        const { page, perPage } = this.cookieKeysPerPage[this.select_db];
        this.$cookies.set(page, this.page, expiresDate.toDate());
        this.$cookies.set(perPage, this.itemsPerPage, expiresDate.toDate());
      }
    },
    async getPageCookie () {
      const { page, perPage } = this.cookieKeysPerPage[this.select_db];
      if (this.$cookies.get(perPage) !== 'null' && this.$cookies.get(perPage)){
        this.itemsPerPage = Number(this.$cookies.get(perPage));
      } else {
        this.itemsPerPage = 10;
      }
      let pageCookies = this.$cookies.get(page);
      if (pageCookies !== 'null' && Number(pageCookies) > 0) {
        this.page = Number(pageCookies);
      } else {
        this.page = 1;
        await this.reloadData()
      }
    },
    async changeItemsPerPage(event){
      this.itemsPerPage = event;
      if(this.page !== 1){
        this.page = 1;
      }else {
        this.reloadData();
      }
      await this.savePageCookie();
    },
  },
  components: {
    masterUserDialog,
    masterProductionDialog,
    masterCustomerDialog,
    MasterSubtype,
    MasterAggregateTypes,
    MasterAssignableUserOrder,
    MasterProductClassificationDialog,
    MasterQuestionSurveyDialog,
    TooltipItemTable,
  }
}
</script>
<style scoped>
.customer-table-db > .v-data-table.v-data-table.v-data-table >>> tr:last-child > th {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 14px !important;
  height: 14px;
  padding: 0px 7px !important;
}

.v-data-table.v-data-table.v-data-table >>> th {
  font-size: 10px !important;
  height: 10px;
  padding: 10px 7px !important;
  vertical-align: top;
  text-wrap-mode: nowrap;
}

</style>
<style lang="scss">
  .box-customer-limit {
    min-width: 420px;
    padding: 0px 10px;
    align-items: center;

    .font-size-limit {
      font-size: 14px
    }

    .customer-items-per-page {
      >.v-input__control {
        min-height: 34px !important;
        >.v-input__slot {
          min-height: 32px !important;
        }
      }
    }
  }
</style>
