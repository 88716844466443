<template>
  <v-row justify="center">
    <v-dialog
      v-model="classificationMstDialog"
      persistent
      overflow-y=initial
    >
      <v-card>
        <v-card-title class="headline">{{this.dialogTitle}}</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols='6'>
                <v-card flat>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>ID</v-col>
                      <v-col cols="3"><v-text-field :readonly="actionMode!=1?true:false" outlined height=5 ma-0 pa-0 hide-details dense v-model='classification_data.id'></v-text-field></v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>分類名<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                      <v-col cols="9"><v-text-field maxlength="255" :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='classification_data.classification_name'></v-text-field></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right text-h6" style="margin-top: 10px;" right></v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols='6' style="margin-left: 0;">
                <v-card flat>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>分類コード<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                      <v-col cols="9"><v-text-field maxlength="255" :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="codeRules" v-model='classification_data.classification_code' placeholder="1234567"></v-text-field></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>大分類</v-col>
                      <v-col cols="9"><v-select
                        v-model="classification_l_id"
                        :items="filterClassification_L" clearable
                        item-text="classification_name"
                        item-value="id"
                        placeholder="大分類"
                        outlined
                        hide-details="auto"
                        dense
                      ></v-select></v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>中分類</v-col>
                      <v-col cols="9"><v-select v-model="classification_m_id"
                        :items="filterClassification_M" clearable
                        placeholder="中分類"
                        outlined
                        item-text="classification_name"
                        item-value="id"
                        hide-details="auto"
                        dense
                      ></v-select></v-col>
                    </v-row>

                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if='this.actionMode!=2'>
            <v-btn color="primary" @click="cancelEvent" style="width: 120px;">キャンセル</v-btn>
          </template>
          <template v-if='this.actionMode==2'>
            <v-btn color="primary" :disabled="!isValid" @click="updateClassification" style="width: 120px;">保存</v-btn>
            <v-btn color="primary" @click="deleteClassification" style="width: 120px;">削除</v-btn>
          </template>
          <v-btn color="primary" :disabled="!isValid && actionMode==1" @click="okEvent" style="width: 120px;">{{okButtonName[actionMode]}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import productClassificationService from "../../services/productClassificationService";
import { mapActions } from 'vuex'
export default {
  name: 'masterCustomerDialog',
  props: {
    dialogTitle: String,
    classificationMstDialog: Boolean,
    actionMode: Number, /* 0:検索, 1:登録, 2:表示 */
    dialogItems: Object,
    readOnly: Boolean
  },
  data: function () {
    return {
      classification_l: [],
      classification_l_id: null,

      classification_m: [],
      classification_m_id: null,
      okButtonName: ['検索', '登録', '閉じる'],
      select_db: -1,
      classification_data: [{
        classification_name: '',
        classification_code: '',
      }],
      codeRules : [v => (!v || v.length < 256 && /^[0-9]*$/.test(v))],
      mergeSubClassifications: [],
    }
  },
  computed: {
    isValid(){
      return (this.classification_data.classification_name)
      && (this.classification_data.classification_code && /^[0-9]*$/.test(this.classification_data.classification_code))
    },
    filterClassification_L() {
      return this.filterClassification(this.classification_l);
    },
    filterClassification_M() {
      return this.filterClassification(this.classification_m);
    }
  },
  methods: {
    ...mapActions({
      activateErrorNotification: 'snackbar/activateErrorNotification',
    }),
    cancelEvent() {
      this.classification_m_id = null
      this.classification_l_id = null
      this.$emit('result', {'res': false, 'message': []})
    },
    reloadData(){
      this.fetClassificationNameList();
      this.$emit('reload')
    },
    async okEvent() {
      if(this.actionMode == 1){
        if(this.classification_m_id){
          this.classification_data.parent_id = this.classification_m_id
        } else this.classification_data.parent_id = this.classification_l_id
        await productClassificationService.createClassification(this.classification_data)
        this.reloadData()
      }
      if(this.actionMode == 0 ){
        this.$emit('result', {'res': true, 'message': this.classification_data})
      }
      this.cancelEvent()
    },
    async deleteClassification(){
      await productClassificationService.deleteClassification(this.classification_data.id)
      this.cancelEvent()
      this.reloadData()
    },
    async updateClassification(){
      if(this.classification_m_id){
        const getClassificationM = this.filterClassification_M.find(item => item.id == this.classification_m_id);
        if (getClassificationM && getClassificationM.parent_id == this.classification_data.id) {
          await this.activateErrorNotification({
            message: `${getClassificationM.classification_name}は${this.classification_data.classification_name}の中分類ですので、変更ができません。 `
          });
          return;
        }
        this.classification_data.parent_id = this.classification_m_id
      } else this.classification_data.parent_id = this.classification_l_id
      await productClassificationService.updateClassification(this.classification_data.id, this.classification_data)
      this.cancelEvent()
      this.reloadData()
    },
    fetClassificationNameList(){
      this.mergeSubClassifications = [];
      productClassificationService.getClassifiCationChild().then(response=>{
        this.classification_l = response.data.data;
        this.classification_l.forEach(item => {
            this.mergeSubClassifications.push(...item.sub_classifications);
        });
      })
    },
    filterClassification(classificationList) {
      if (this.actionMode == 2) {
        return classificationList.filter(item => item.id !== this.classification_data.id);
      }
      return classificationList;
    },
  },
  watch: {
    classificationMstDialog: function (newClassificationMstDialog, oldClassificationMstDialog) {
      oldClassificationMstDialog == oldClassificationMstDialog
      if(newClassificationMstDialog == true){
        this.classification_data = JSON.parse(JSON.stringify(this.dialogItems))
      }
    },

    classification_l_id(){
      if(this.classification_l_id){
        let selectedClassification_l = this.classification_l.find(item => item.id === this.classification_l_id)
        this.classification_m = selectedClassification_l.sub_classifications;
        const subclassIds = new Set(this.classification_m.map(item => item.id));
        if (!subclassIds.has(this.classification_m_id)) {
          this.classification_m_id = null;
        }
      }
      else {
        this.classification_m = this.mergeSubClassifications;
      }
    },

    classification_m_id() {
      if (!this.classification_l_id) {
        this.classification_m = this.mergeSubClassifications;
      }
    },

    dialogItems: {
      immediate: true,
      handler: function(val) {
        if(this.actionMode == 2){
          if (val && val.classification_m === null) {
            this.classification_m = this.mergeSubClassifications;
          }
          if(val.classification_l.id){
            this.classification_l_id = val.classification_l.id || null
            this.classification_m_id = val.classification_m.id ? val.classification_m.id : null
          }
        } else if(this.actionMode == 1) {
          this.classification_m = this.mergeSubClassifications;
        }
      }
    }
  },
   async created() {
    await this.fetClassificationNameList()
   }
}
</script>
<style>
.v-text-field input {
  font-size: 1.0em;
  height: 1.1em;
  margin: 0.1em;
  padding: 0.1em;
}

</style>
