<template>
  <v-card color="grey lighten-5" class="my-3" id="all-matter-search-table">
    <!-- 問い合わせ一覧 -->
    <v-data-table
      :headers="matter_headers"
      :items="matterList"
      item-key="matterId"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hide-default-footer
      class="elevation-4 mx-3"
      :item-class="itemRowColorText"
      :items-per-page="itemsPerPage"
      no-data-text="検索条件に一致するお問い合わせはありません"
      @dblclick:row="inquiryHandleTransition">
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-dialog
            persistent
            v-if="!$store.state.auth.user.isGuest"
            v-model="changeHeaderDialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-row>
                <v-col cols="4" class="d-flex items-center" style="height: 64px; padding: 0px; min-width: 420px;">
                  <v-col cols="6" style="width: 200px" class="d-flex items-center">
                    <div class="items-center justify-center">
                      <p class="text-subtitle-1 mt-2">表示件数:</p>
                    </div>
                    <div style="width: 50%">
                      <v-select
                        class="square ml-2"
                        :value="itemsPerPage"
                        :items="listRowLimit"
                        label=""
                        solo
                        flat
                        outlined
                        dense
                        @change="$emit('changePerPage', $event)"
                      ></v-select>
                    </div>
                  </v-col>
                </v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    rounded
                    class="mb-2 float-right"
                    v-bind="attrs"
                    v-on="on"
                  >
                    表示項目設定
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-title class="text-h5">表示項目設定</v-card-title>
              <v-divider></v-divider>
              <div class="d-flex" style="margin: -10px 24px">
                <v-checkbox
                  v-model="selectAll"
                  style="padding-left: 2px"
                ></v-checkbox>
                <span style="margin: auto 65px;font-weight: bold">項目一覧</span>
              </div>
              <v-divider></v-divider>
              <draggable tag="ul" :list="all_matter_field_settings" @change="reOder">
                <transition-group>
                  <v-col
                    class="list-group-item"
                    v-for="(settings,index) in all_matter_field_settings"
                    :key="index"
                  >
                    <v-row style="border-bottom: 1px solid #E0E0E0">
                      <v-col cols="2"  style="margin-top:-20px;margin-bottom:-35px;margin-left: -10px">
                        <v-checkbox
                        v-model="settings.selected"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="6" style="margin: auto">
                        <span>{{ settings.display_field_name }}</span>
                      </v-col>
                      <v-col cols="2" style="margin: auto">
                        <v-icon class="handle">mdi-drag</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                </transition-group>
              </draggable>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" large class="px-7" @click="saveSettings">保存</v-btn>
                <v-btn rounded color="warning" large  class="px-5" @click="closeChangeHeaderDialog">閉じる</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.matterTitle`]="{ item }">
        <tooltip-item-table :tooltip-text="item.matterTitle"/>
      </template>
      <template v-slot:[`item.product_name`]="{ item }">
        <tooltip-item-table :tooltip-text="item.product_name"/>
      </template>
      <template v-slot:[`item.content`]="{ item }">
        <tooltip-item-table :tooltip-text="item.content"/>
      </template>
      <template v-slot:[`item.correspondence_content`]="{ item }">
        <tooltip-item-table :tooltip-text="item.correspondence_content"/>
      </template>
    </v-data-table>

    <!-- ページネーション -->
    <div class="text-center py-1" v-show="matterList.length > 0">
      <v-pagination :value="currentPage" :length="pageCount" total-visible="7" @input="$emit('currentPage', $event)"></v-pagination>
    </div>

    <v-overlay :value="loadingCount > 0">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>

  </v-card>
</template>

<script>
import constant from '@/components/helpers/const';
import userSettingService from "@/services/userSettingService";
import draggable from 'vuedraggable'
import TooltipItemTable from '@/components/TooltipItemTable.vue';

export default {
  components: {
    draggable,
    TooltipItemTable,
  },
  props: {
    // テーブルに表示するデータ(バックエンドから取得)
    matterList: Array,
    itemCount: Number,
    inquiryHistoryFlg: Boolean,
    searchByCategory: Boolean,
    pageCount: Number,
    sortParam: Object,
    silentLoad: Boolean,
    current_header_settings: Array,
    matter_headers: Array,
    currentPage: Number,
    itemsPerPage: Number,
  },
  data() {
    return {
      // テーブルに表示する行数
      listRowLimit: [10, 20, 50, 100],
      sortBy: null,
      sortDesc: null,
      loadingCount: 0,

      changeHeaderDialog: false,

      //todo header display setting
      all_matter_field_settings: constant.allMatterfieldSettings,
      // テーブルのヘッダー
      // 問い合わせ履歴の案件フラグ
      inquiryHistoryMatterFlg: false,
    }
  },
  methods: {
    reOder() {
      const newSettingList = [...this.all_matter_field_settings].map((item, index) => {
        const newOrder = index + 1;
        item.hasChanged = item.sortOrder !== newOrder;
        if (item.hasChanged) {
          item.sortOrder = newOrder;
        }
        return item;
      });
      this.all_matter_field_settings = newSettingList;
    },
    async saveSettingOrder(selected) {
      let data = {
        matter_header_orders: [],
        matter_field_orders: []
      };
      if (selected.length > 0){
        this.all_matter_field_settings.forEach(setting => {
          data.matter_header_orders.push(setting.display_field_name);
          data.matter_field_orders.push(setting.field);
        });
      }else {
        this.all_matter_field_settings = constant.allMatterfieldSettings;
      }
      await userSettingService.updateMatterSettingOrders(data);
    },
    async reloadList(userId) {
      userSettingService.getUserSettings(userId).then(Response => {
        let data = Response.data.data;
        let setting = [];
        if (data && data.matter_fields && data.matter_field_orders && data.matter_header_orders){
          let matter_header_orders = data.matter_header_orders.split(';').filter(item => item !== '');
          let matter_field_orders = data.matter_field_orders.split(';').filter(item => item !== '');
          let matter_fields = data.matter_fields.split(';').filter(item => item !== '');
          matter_field_orders.forEach((value,index) => {
            setting.push(Object.assign({field: value, display_field_name: matter_header_orders[index]}));
          })
          if (matter_fields.length >0){
            this.all_matter_field_settings = setting.map((item)=>{
              return {...item, selected: !!matter_fields.includes(item.field)}
            });
          }else {
            this.defaultChoose(setting);
          }
        }else {
          this.defaultChoose(this.all_matter_field_settings);
        }
      })
    },
    defaultChoose(data){
      let defaultChoose = [];
      this.matter_headers.map((item) => {
        defaultChoose.push(item.value);
      })
      this.all_matter_field_settings = data.map((item)=>{
        return {...item, selected: !!defaultChoose.includes(item.field)}
      });
    },
    itemRowColorText: function (item) {
      return item.matterUrgency === 2 ? 'text-color-orange' : (item.matterUrgency === 1  ? 'text-color-red' : '');
    },
    // テーブルの行をダブルクリックした時のイベント
    inquiryHandleTransition(event, { item }) {
      // 画面遷移先のpath
      const path = `/inquiryhandle/${item.matterId}`;
      // storeから「navbarMatterLists」を取得
      let navbarMatterList = this.$store.getters.getMatterList
      // Vuexの「navbarMatterLists」に既に登録済の案件だった場合、追加処理は行わない
      for (let i = 0; i < navbarMatterList.length; i++) {
        if (navbarMatterList[i].matterLink === path) {
          // 対応画面へ画面遷移
          this.$router.push(path);
          return;
        }
      }
      // Vuexの「navbarMatterLists」へ対応ページの情報をpush
      const newNavbarMatter = {
        matterId: item.matterId,
        matterTitle: item.matterTitle,
        matterLink: path,
        matterPhone: item.inquiryType ? true : false,
        matterUrgency:  item.matterUrgency,
        serverFlag: item.serverFlag
      };
      this.$store.dispatch('updateNavbarMatterListAction', newNavbarMatter);
      // 対応画面へ画面遷移
      this.$router.push(path);
    },

    //change header display
    closeChangeHeaderDialog(){
      this.changeHeaderDialog = false
      this.$emit('cancelHeaderChange')
    },
    async saveSettings(){
      let data = {
        matter_headers: [],
        matter_fields: []
      };
      let selected = [];
      this.all_matter_field_settings.filter(function (setting) {
        if (setting.selected === true)
            selected.push(Object.assign({display_field_name: setting.display_field_name, field: setting.field}));
      });
      selected.forEach(setting => {
        data.matter_headers.push(setting.display_field_name);
        data.matter_fields.push(setting.field);
      });
      await this.saveSettingOrder(selected);
      await userSettingService.updateMatterSettings(data);
      this.$emit('saveSettings');
      this.changeHeaderDialog = false;
    },

  },
  computed: {
    selectAll: {
      get: function () {
        return this.all_matter_field_settings ? this.all_matter_field_settings.every((item) => item.selected) : false;
      },
      set: function (value) {
        this.all_matter_field_settings = this.all_matter_field_settings.map((item) => ({...item,selected: !!value}))
      }
    },
  },
  watch: {
    changeHeaderDialog(val){
      console.log(val)
      if (val === false){
        this.reloadList(this.$store.state.auth.user.id);
      }
    },
    searchByCategory:{
      immediate:true,
      handler(val){
        if (val){
          this.page = 1;
        }
      }
    },
    async sortParam(){
      this.sortBy = this.sortParam.sortBy;
      this.sortDesc = this.sortParam.sortDesc;
    },
    async sortBy(){
      this.sortParam.sortBy = this.sortBy;
      this.$emit('sortParam', this.sortParam);
    },
    async sortDesc(){
      this.sortParam.sortDesc = this.sortDesc;
      this.$emit('sortParam', this.sortParam);
    },
  },
  mounted() {
    if (typeof this.itemCount !== 'undefined') {
      this.itemsPerPage = this.itemCount;
    }
    this.reloadList(this.$store.state.auth.user.id);
    this.page = this.currentPage;
  },
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #90caf9;
  text-wrap-mode: nowrap;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
  /* font-size: 10px !important; */
  background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
  background: #fff;
}

.v-data-table ::v-deep tr:hover td {
  background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
  /* font-family: sans-serif !important; */
}
>>>tr.text-color-red{
  color: red;
}

>>>tr.text-color-orange{
  color: orange;
}
.ellipsis-design{
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style>
#all-matter-search-table > .v-data-table > .v-data-table__wrapper > table > tbody{
  word-break: break-word;
}
</style>
