<template>
  <v-tooltip
    top
    max-width="40%"
    color="#BBDEFB"
    v-if="tooltipText && tooltipText.length > textLength"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on" class="custome-text-tooltip">{{ truncateText(textLength)  }}</div>
    </template>
    <span class="text-black">{{ truncateText(textTooltipLength) }}</span>
  </v-tooltip>

  <span v-else>{{ tooltipText }}</span>
</template>

<script>
export default {
  name: 'tooltip-item-table',
  props: {
    tooltipText: { type: String, default: '' },
    textLength: { type: Number, default: 50 },
    textTooltipLength: { type: Number, default: 500 },
  },
  data: function () {
    return {
    }
  },
  methods: {
    truncateText(textLength) {
      const lengthLimit = Number(textLength);
      // Truncate text longer than 50
      if (this.tooltipText && this.tooltipText.length > lengthLimit) {
        return `${ this.tooltipText.substring(0, lengthLimit) }...`;
      } else {
        return this.tooltipText;
      }
    }
  },
}
</script>
<style scoped>
  .text-black{
    color: black;
  }
  .custome-text-tooltip {
    height: 100%;
    vertical-align: center;
    align-content: center;
  }
</style>
